import { THEMES } from '../constants';

export const getSubapp = () => {
  return process.env.REACT_APP_SUBAPP === 'sentinels' ? THEMES.SENTINELS : THEMES.PLUNJR;
};

export const getSmallSVGLogoURL = () => {
  return process.env.REACT_APP_SUBAPP === 'sentinels'
    ? '/static/logo-small-sentinels.svg'
    : '/static/logoHybridSmall.svg';
};

export const isSentinelsApp = () => {
  return process.env.REACT_APP_SUBAPP === 'sentinels';
};
