// workaround, FCM onMessage method from lib is broken + sockets for Safari
import { MutableRefObject, useEffect, useRef, useState } from 'react';
import firebase, { isFCMChannelSupported } from '../lib/firebase';
import {
  DecodedNotificationData,
  FCMNotificationEvent,
  SocketNotificationEvent,
} from '../types/notificationMessage';

const socketReconnectTimeout = 5 * 1000;
const shouldNotReconnectByCode = (event): boolean => {
  console.warn('WebSocket close error', event?.code);
  // close codes: https://developer.mozilla.org/en-US/docs/Web/API/CloseEvent/code
  // obvious errors below
  // usually token error or BE error
  if ([1011].includes(event?.code)) return true;
  // usually network error
  else if ([1006].includes(event?.code)) return false;
  return false;
};
/**
 * Previous solution with 'onMessage' could trigger the same event multiple times
 * **/
export const useFCMBGMessageOrSocketChannel: any = (
  // @ts-ignore
  { uuid, token } = {},
  watchExistingVariables
) => {
  const [message, setMessage] = useState<any>();
  const channelRef: MutableRefObject<any> = useRef();
  const socketRef: MutableRefObject<any> = useRef();
  const isSocketDisconnectedRef: MutableRefObject<boolean> = useRef(false);
  const timeoutRef: MutableRefObject<any> = useRef();

  const tryReconnect = (event) => {
    if (shouldNotReconnectByCode(event)) return;
    timeoutRef.current && clearTimeout(timeoutRef.current);
    timeoutRef.current = setTimeout(function () {
      if (!isSocketDisconnectedRef.current) return;
      socketRef.current = null;
      connectSocket({ uuid, token });
    }, socketReconnectTimeout);
  };

  const connectSocket = ({ uuid, token }) => {
    if (!socketRef.current)
      socketRef.current = new WebSocket(
        `${process.env.REACT_APP_NOTIFICATION_SOCKETS_URL}${uuid}/?token=${token}`
      );
    socketRef.current.onopen = (event) => {
      timeoutRef.current && clearTimeout(timeoutRef.current);
      isSocketDisconnectedRef.current = false;
      console.log('WebSocket is open now.');
    };
    socketRef.current.onerror = (event) => {
      console.error('WebSocket error observed:', event);
    };
    socketRef.current.onclose = (event) => {
      isSocketDisconnectedRef.current = true;
      console.log(`Socket closed. Reinitialization in a few sec.`);
      tryReconnect(event);
    };
    if (!socketRef.current.onmessage)
      socketRef.current.onmessage = (event: SocketNotificationEvent) => {
        console.log('Message event on WebSocket \n', event);
        let decodedData: DecodedNotificationData;
        try {
          decodedData = JSON.parse(event.data);
        } catch (error) {
          console.error('Unable to pares socket data \n', error);
        }
        setMessage(decodedData);
      };
  };

  useEffect(() => {
    if (
      WebSocket &&
      uuid &&
      token &&
      !channelRef.current &&
      (!socketRef.current || (socketRef.current && !socketRef.current.onmessage))
    ) {
      connectSocket({ uuid, token });
    } else if (
      isFCMChannelSupported() &&
      uuid &&
      token &&
      !socketRef.current &&
      (!channelRef.current || (channelRef.current && !channelRef.current.onmessage))
    ) {
      channelRef.current = new BroadcastChannel('fcm-channel');
      channelRef.current.onmessage = (event: FCMNotificationEvent) => {
        console.log('Message event from FCM \n', event);
        let decodedData: DecodedNotificationData = {
          data_message: {
            thread_uuid: event.data.data.thread_uuid,
            type: event.data.data.type,
          },
          message_body: event.data.notification?.body,
          message_title: event.data.notification?.title,
        };
        setMessage(decodedData);
      };
    } else if (uuid && token && !channelRef.current && !socketRef.current) {
      console.warn('No supported solutions for notifications');
    } else if (!token || !uuid) {
      if (channelRef.current) channelRef.current.onmessage = null;
      if (socketRef.current) socketRef.current.onmessage = null;
      channelRef.current = null;
      socketRef.current = null;
    }
    return () => {
      // channelRef.current?.close && channelRef.current.close();
      // channelRef.current = null;
      if (channelRef.current) channelRef.current.onmessage = null;
      if (socketRef.current) socketRef.current.onmessage = null;
    };
  }, [watchExistingVariables]);
  return message;
};
