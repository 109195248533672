import type { FC } from 'react';
import { Dispatch, MutableRefObject, SetStateAction, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { alpha, Box, Button, useTheme } from '@mui/material';
import useAuth from '../../../hooks/useAuth';
import Scrollbar from '../../../components/Scrollbar';
import ChatMessage from '../../../components/dashboard/chat/ChatMessage';
import { MessagingMessage } from '../../../types/messaging_message';
import { MessagingParticipant } from '../../../types/messaging_participant';
import { getTime, parseISO } from 'date-fns';
import { getUserNameFromUser } from '../../../utils/messagingNames';
import LoadMoreButton from '../LoadMoreButton';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { getMyRoles } from '../../../utils/ability';
import { LoadMoreButtonsConfig } from '../../../types/messaging_load_more_buttons_config';
import { experimentalStyled } from '@mui/material/styles';

interface MessagingChatMessagesProps {
  messages: Array<MessagingMessage>;
  participants: Array<MessagingParticipant>;
  onLoadMoreMessagesClick: any;
  isLoadMoreMessagesVisible: boolean;
  shouldChatScroll: any;
  isLoading: boolean;
  onScrollUp: (event: any) => void;
  onPostInitPayment: any;
  onPostEndChat: any;
  onPostRequestLogin: any;
  handleChangeMesssagePinnedStatus: (data: { is_pinned: boolean; uuid: string }) => void;
  setMessageToScrollTo: Dispatch<SetStateAction<string>>;
  messageToScrollTo: string;
  changeMessagePinnedPostFetchStatus: string | null;
  newMessagesButtonConfig: {
    visible?: boolean;
    title?: string;
    onClick?: () => void;
  };
  loadMoreButtonsConfig: LoadMoreButtonsConfig;
  handleConfirmAttachmentAdd: any;
  thread: any;
}

const NoMessages = experimentalStyled('div')(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  height: '100%',
  width: '100%',
  textAlign: 'center',
  marginLeft: 'auto',
  marginRight: 'auto',
  fontWeight: 'bold',
  color: theme.palette.text.secondary,
}));

const MessagingChatMessages: FC<MessagingChatMessagesProps> = (props) => {
  const {
    messages,
    participants,
    onLoadMoreMessagesClick,
    isLoadMoreMessagesVisible,
    shouldChatScroll,
    onScrollUp,
    isLoading,
    handleChangeMesssagePinnedStatus,
    setMessageToScrollTo,
    messageToScrollTo,
    changeMessagePinnedPostFetchStatus,
    newMessagesButtonConfig,
    loadMoreButtonsConfig,
    handleConfirmAttachmentAdd,
    thread,
    ...other
  } = props;
  const rootRef = useRef<any>(null);

  const [messageRef, setMessageRef] = useState<MutableRefObject<HTMLDivElement>>(null);

  const { user } = useAuth();

  const theme = useTheme();

  const [showScrollToBottomButton, setShowScrollToBottomButton] = useState<boolean>(false);

  const scrollToBottom = () => {
    // eslint-disable-next-line no-underscore-dangle
    if (rootRef?.current?._container) {
      // eslint-disable-next-line no-underscore-dangle
      rootRef.current._container.scrollTop = rootRef.current._container.scrollHeight;
    }

    if (showScrollToBottomButton) setShowScrollToBottomButton(false);
  };

  useEffect(() => {
    // to not scroll when not fully rendered
    setTimeout(() => scrollToBottom(), 100);
  }, [shouldChatScroll]);

  const handleShowScrollToBottomButton = () => {
    if (!rootRef) return;
    const scrollTotal =
      rootRef.current._container.scrollHeight - rootRef.current._container.clientHeight;
    if (Math.abs(scrollTotal - rootRef.current._container.scrollTop) > 1000)
      setShowScrollToBottomButton(true);
    else setShowScrollToBottomButton(false);
  };

  useEffect(() => {
    if (rootRef?.current?._container) {
      rootRef.current._container.addEventListener('scroll', handleShowScrollToBottomButton, false);
    }
    return () => {
      if (rootRef && rootRef.current) {
        rootRef.current._container.removeEventListener(
          'scroll',
          handleShowScrollToBottomButton,
          false
        );
      }
    };
  }, [rootRef]);

  useEffect(() => {
    if (messageRef?.current) {
      messageRef.current.scrollIntoView({ block: 'end' });
      messageRef.current.style.transition = 'all 300ms ease-out';
      messageRef.current.style.backgroundColor = alpha(theme.palette.primary.light, 0.2);
      setTimeout(() => {
        messageRef.current.style.backgroundColor = theme.palette.background.default;
        setMessageRef(null);
        setMessageToScrollTo(null);
      }, 450);
    }
  }, [messageRef]);

  return (
    <>
      <Scrollbar
        onScrollY={onScrollUp}
        options={{
          suppressScrollX: true,
        }}
        ref={rootRef}
        style={
          {
            // height: `calc(100% - ${sizes.messaging.toolbarHeight} - ${sizes.messaging.pinnedMessagesHeight})`,
          }
        }
        {...other}
      >
        <Box
          sx={{
            p: 2,
          }}
        >
          {/* {messages.length > 0 && (
            <LoadMoreButton
              title={'Load older messages'}
              onClick={onLoadMoreMessagesClick}
              isVisible={isLoadMoreMessagesVisible}
              disabled={isLoading}
            />
          )} */}
          {messages.length > 0 && (
            <LoadMoreButton
              title={loadMoreButtonsConfig.loadOlder?.title}
              onClick={loadMoreButtonsConfig.loadOlder?.onClick}
              isVisible={loadMoreButtonsConfig.loadOlder?.visible}
              disabled={loadMoreButtonsConfig.loadOlder?.disabled}
              icon={loadMoreButtonsConfig.loadOlder?.icon}
            />
          )}
          {messages.length > 0 ? (
            messages.map((message, idx) => {
              let participant = message.sender
                ? participants.find(
                    (_participant) =>
                      _participant.uuid === message.sender.uuid ||
                      _participant.username === message.sender.username
                  )
                : null;
              if (participant && !participant.senderName) {
                participant = { ...participant, senderName: getUserNameFromUser(participant) };
              }
              if (message.sender && message.sender?.uuid === user.uuid) {
                participant = {
                  avatar: user?.profile_image?.thumbnails?.avatar,
                  username: user.username,
                  uuid: message?.sender?.uuid,
                  senderName: 'Me',
                  senderType: 'user',
                };
              } else if (!participant) {
                const name = getUserNameFromUser(message.sender);
                participant = {
                  avatar: message.sender_avatar,
                  senderName: name,
                  username: name,
                  uuid: message?.sender?.uuid || getTime(new Date()).toString(),
                  senderType: null,
                };
              }
              if (participant && message.sender_avatar && !participant.avatar)
                participant.avatar = message.sender_avatar;

              const isAdmin =
                getMyRoles()?.map(
                  (role) =>
                    role.toLowerCase().includes('admin') && !role.toLowerCase().includes('vendor')
                ).length > 0;

              return (
                <ChatMessage
                  uuid={message.uuid}
                  textBody={message.text}
                  imageBody={message.image}
                  fileBody={message.file}
                  contentType={message.image ? 'image' : 'text'}
                  createdAt={parseISO(message.created)}
                  key={message.uuid}
                  senderAvatar={participant?.avatar}
                  senderName={participant.senderName}
                  senderType={participant?.senderType}
                  sender={participant}
                  extraContext={message.extra_context}
                  automatedTag={message.automated_tag}
                  action={message.action}
                  pinConfig={{
                    is_pinned: message.is_pinned,
                    showPinActions: isAdmin,
                    handleChangeMesssagePinnedStatus: handleChangeMesssagePinnedStatus,
                    messageToScrollTo: messageToScrollTo,
                    setMessageToScrollToRef: setMessageRef,
                    changeMessagePinnedPostFetchStatus: changeMessagePinnedPostFetchStatus,
                  }}
                />
              );
            })
          ) : (
            <NoMessages>No messages</NoMessages>
          )}
        </Box>
        {messages.length > 0 && (
          <LoadMoreButton
            title={loadMoreButtonsConfig.loadNewer?.title}
            onClick={loadMoreButtonsConfig.loadNewer?.onClick}
            isVisible={loadMoreButtonsConfig.loadNewer?.visible}
            disabled={loadMoreButtonsConfig.loadNewer?.disabled}
            icon={loadMoreButtonsConfig.loadNewer?.icon}
          />
        )}
      </Scrollbar>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          position: 'relative',
          bottom: '48px',
          opacity: newMessagesButtonConfig?.visible ? 100 : showScrollToBottomButton ? 100 : -1,
          zIndex: newMessagesButtonConfig?.visible ? 100 : showScrollToBottomButton ? 100 : -1,
          transition: 'all 150ms ease-out',
        }}
      >
        <Button
          variant={'contained'}
          onClick={() => {
            newMessagesButtonConfig?.onClick && newMessagesButtonConfig?.onClick();
            scrollToBottom();
          }}
        >
          {newMessagesButtonConfig?.title ?? 'Go to recent messages'}
          <ArrowDownwardIcon sx={{ ml: 1 }} />
        </Button>
      </Box>
    </>
  );
};

MessagingChatMessages.propTypes = {
  // @ts-ignore
  messages: PropTypes.array,
  participants: PropTypes.array,
};

export default MessagingChatMessages;
