import type { FC } from 'react';
import {
  Dispatch,
  MouseEvent,
  MutableRefObject,
  SetStateAction,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { Link as RouterLink, useNavigate, useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { formatDistanceToNowStrict } from 'date-fns';
import { Lightbox } from 'react-modal-image';
import {
  Avatar,
  Box,
  CircularProgress,
  IconButton,
  Link,
  Menu,
  MenuItem,
  MenuList,
  Typography,
} from '@mui/material';
import { alpha, experimentalStyled, useTheme } from '@mui/material/styles';
import { mainAppColors } from '../../../theme';
import Linkify from 'react-linkify';
import { SxProps } from '@mui/system';
import PushPinIcon from '@mui/icons-material/PushPin';
import ClearIcon from '@mui/icons-material/Clear';
import useModal from '../../../hooks/useModal';
import { RequestStatus } from '../../../utils/RequestStatus';
import ChatMessageFileAttachment from './ChatMessageFileAttachment';
import ExifReader from 'exifreader';
import { checkIfSafari } from '../../../utils/browser-detect';
import LockClockIcon from '@mui/icons-material/LockClock';
import { GrantAccessToChatContext } from '../../../contexts/GrantAccessToChatContext';
import { MessagingParticipant } from '../../../types/messaging_participant';
import { TouchApp } from '@mui/icons-material';
import { messageActions } from '../../../utils/messageActions';
import { getMessagingDetailsPath } from '../../../routesPaths';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

const StyledTextContainer = experimentalStyled(Typography)(({ theme }) => ({
  ['a']: {
    color: theme.palette.text.disabled,
  },
}));

interface ImageMessageType {
  uuid;
  url: string;
  thumbnails: {
    thumb: string;
    avatar: string;
  };
}

interface FileBodyType {
  mime_type: string;
  name: string;
  size: number;
  url: string;
  uuid: string;
}

interface ExtraContextType {
  link: string;
  type: string;
  [key: string]: any;
}

interface ChatMessageProps {
  uuid: string;
  contentType: string;
  textBody?: string;
  imageBody?: ImageMessageType;
  fileBody?: FileBodyType;
  createdAt: Date | number;
  senderAvatar?: string;
  senderName: string;
  senderType: 'contact' | 'user';
  sender: MessagingParticipant;
  extraContext: ExtraContextType;
  boxSx?: SxProps;
  automatedTag: string | null;
  action: string | null;
  hideFingerMenu?: boolean;
  pinConfig: {
    is_pinned: boolean;
    isInPinnedMessagesPopUp?: boolean;
    showPinActions?: boolean;
    handleChangeMesssagePinnedStatus?: (data: { is_pinned: boolean; uuid: string }) => void;
    messageToScrollTo?: string;
    setMessageToScrollToRef?: Dispatch<SetStateAction<MutableRefObject<HTMLDivElement>>>;
    changeMessagePinnedPostFetchStatus?: string | null;
  };
}

const NameContainer = experimentalStyled('div')`
  color: ${(props: any) => {
    return props.isBlueBg ? mainAppColors.messageUsername : props.theme.palette.primary.main;
  }};
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

const ChatMessage: FC<ChatMessageProps> = (props) => {
  const {
    uuid,
    textBody,
    imageBody,
    fileBody,
    contentType,
    createdAt,
    senderAvatar,
    senderName,
    senderType,
    extraContext,
    boxSx = {},
    sender,
    automatedTag,
    action,
    hideFingerMenu = false,
    pinConfig: {
      is_pinned,
      isInPinnedMessagesPopUp = false,
      showPinActions = false,
      handleChangeMesssagePinnedStatus,
      messageToScrollTo = null,
      setMessageToScrollToRef = null,
      changeMessagePinnedPostFetchStatus,
    },
    ...other
  } = props;

  const theme = useTheme();
  const navigate = useNavigate();

  const messageRef = useRef<HTMLDivElement>(null);

  const isSafari = checkIfSafari();

  const { Component: ConfirmUnPinModal, ...confirmUnPinModal } = useModal();

  const [expandMedia, setExpandMedia] = useState<boolean>(false);

  const [rotationFix, setRotationFix] = useState<string>(null);

  const [pinVisible, setPinVisible] = useState<boolean>(false);
  const [showPinSpinner, setShowPinSpinner] = useState<string>(null);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const menuOpen = Boolean(anchorEl);
  const handleOpenMessageMenu = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseMessageMenu = () => {
    setAnchorEl(null);
  };

  const { uuid: threadUuid } = useParams();

  const isAnyMenuActionAvailable =
    !hideFingerMenu &&
    ((Boolean(extraContext?.access_uuid) &&
      !Boolean(extraContext?.access_approved_at) &&
      automatedTag === 'ACCESS_REQUEST') ||
      (action === messageActions.threadShow &&
        extraContext?.resource_thread_uuid &&
        extraContext?.resource_thread_uuid !== threadUuid));

  const { handleOpenGrantAccessModal } = useContext(GrantAccessToChatContext);

  useEffect(() => {
    if (messageRef?.current && messageToScrollTo === uuid && !!setMessageToScrollToRef) {
      setMessageToScrollToRef(messageRef);
    }
  }, [messageToScrollTo, messageRef?.current]);

  useEffect(() => {
    if (!RequestStatus.isFetching(changeMessagePinnedPostFetchStatus) && showPinSpinner)
      setShowPinSpinner(null);
  }, [changeMessagePinnedPostFetchStatus]);

  const correctFullImgExifRotation = async () => {
    if (imageBody.url.match(/.(jpg|jpeg|gif)$/g)) return;
    const tags = await ExifReader.load(imageBody.url);
    const orientation = tags['Orientation'] ?? null;
    if (!orientation) return;
    switch (orientation.value) {
      case 3:
        setRotationFix('180deg');
        break;
      case 6:
        setRotationFix('90deg');
        break;
      case 8:
        setRotationFix('270deg');
        break;
    }
  };

  useEffect(() => {
    if (imageBody?.url && !isSafari) {
      correctFullImgExifRotation();
    }
  }, [imageBody]);

  useEffect(() => {
    if (expandMedia && rotationFix) {
      const lightboxImg = document.getElementById('react-modal-image-img');
      if (!lightboxImg) return;
      lightboxImg.style.transform = `translate3d(-50%, -50%, 0px) rotate(${rotationFix})`;
    }
  }, [expandMedia, rotationFix]);

  return (
    <Box
      ref={messageRef}
      sx={{
        display: 'flex',
        mb: 1,
        pt: 1,
        ...boxSx,
      }}
      {...other}
    >
      {is_pinned && (
        <ConfirmUnPinModal
          {...confirmUnPinModal.props}
          title={'Un-pin message'}
          isConfirmation={true}
          onConfirm={() => {
            handleChangeMesssagePinnedStatus({ is_pinned: false, uuid });
            setShowPinSpinner(uuid);
            confirmUnPinModal.close();
          }}
          onCancel={() => {
            confirmUnPinModal.close();
          }}
          confirmLabel={'Yes'}
          cancelLabel={'No'}
          description={'Are you sure you want to un-pin this message?'}
        />
      )}
      <Box
        sx={{
          display: 'flex',
          flexDirection: senderType !== 'user' || isInPinnedMessagesPopUp ? 'row' : 'row-reverse',
          maxWidth: 500,
          ml: senderType !== 'user' || isInPinnedMessagesPopUp ? 0 : 'auto',
          ...(isInPinnedMessagesPopUp && { width: '100%', pr: 1 }),
        }}
      >
        <Avatar
          src={senderAvatar}
          sx={{
            height: 32,
            ml: senderType !== 'user' || isInPinnedMessagesPopUp ? 0 : 2,
            mr: senderType !== 'user' || isInPinnedMessagesPopUp ? 2 : 0,
            width: 32,
          }}
        />
        <div
          {...(!isInPinnedMessagesPopUp &&
            showPinActions && {
              onMouseEnter: () => {
                if (!pinVisible) setPinVisible(true);
              },
              onMouseLeave: () => {
                setPinVisible(false);
              },
            })}
          style={{
            ...(isInPinnedMessagesPopUp && { width: '100%' }),
          }}
        >
          <Box
            sx={{
              backgroundColor: senderType === 'user' ? 'primary.main' : 'background.paper',
              borderRadius: 1,
              boxShadow: 1,
              color: senderType === 'user' ? 'primary.contrastText' : 'text.primary',
              px: 2,
              py: 1,
              ...(is_pinned && {
                backgroundColor:
                  senderType === 'user'
                    ? alpha(theme.palette.primary.main, 0.7)
                    : alpha(theme.palette.primary.main, 0.1),
              }),
              ...(isInPinnedMessagesPopUp && { width: '100%' }),
            }}
          >
            {/*@ts-ignore*/}
            <NameContainer isBlueBg={senderType === 'user'}>
              <Link
                color="inherit"
                component={RouterLink}
                to="#"
                variant="subtitle2"
                sx={{ wordBreak: 'break-all' }}
              >
                {senderName}
              </Link>
              <Box
                display={'flex'}
                justifyContent={'flex-end'}
                alignItems={'center'}
                sx={{ py: 0.5 }}
              >
                {is_pinned && !isInPinnedMessagesPopUp && (
                  <Box display={'flex'} alignItems={'center'} sx={{ mx: 2 }}>
                    <PushPinIcon
                      fontSize="small"
                      sx={{ color: senderType === 'user' && 'background.paper' }}
                    />
                    <Typography
                      variant={'subtitle2'}
                      sx={{ color: senderType === 'user' && 'background.paper' }}
                    >
                      Pinned
                    </Typography>
                  </Box>
                )}
                {showPinActions && !isInPinnedMessagesPopUp && (
                  <>
                    {RequestStatus.isFetching(changeMessagePinnedPostFetchStatus) &&
                    showPinSpinner === uuid ? (
                      <CircularProgress
                        size={28}
                        sx={{
                          color: senderType === 'user' && 'background.paper',
                          ...(!is_pinned && { ml: 1 }),
                        }}
                      />
                    ) : (
                      <IconButton
                        sx={{
                          p: 0.5,
                          opacity: pinVisible || isInPinnedMessagesPopUp ? 1 : 0.1,
                          transition: 'opacity 150ms ease-out',
                          color: senderType === 'user' ? 'background.paper' : 'primary.main',
                          ...(!is_pinned && { ml: 1 }),
                        }}
                        disabled={RequestStatus.isFetching(changeMessagePinnedPostFetchStatus)}
                        onClick={() => {
                          if (is_pinned) confirmUnPinModal.open();
                          else {
                            handleChangeMesssagePinnedStatus({ is_pinned: true, uuid });
                            setShowPinSpinner(uuid);
                          }
                        }}
                        size="large"
                      >
                        {is_pinned ? (
                          <ClearIcon fontSize="small" />
                        ) : (
                          <PushPinIcon fontSize="small" />
                        )}
                      </IconButton>
                    )}
                  </>
                )}
              </Box>
            </NameContainer>
            <Box sx={{ mt: 1 }}>
              {contentType === 'image' ? (
                <Box
                  onClick={(): void => setExpandMedia(true)}
                  sx={{
                    mt: 2,
                    '& img': {
                      cursor: 'pointer',
                      height: 380,
                      width: '100%',
                    },
                  }}
                >
                  <img
                    style={{ objectFit: 'contain' }}
                    alt="Attachment"
                    src={imageBody?.thumbnails?.thumb}
                  />
                  {textBody !== '[image attached]' && <Linkify>{textBody}</Linkify>}
                </Box>
              ) : (
                <StyledTextContainer color="inherit" variant="body1">
                  <Linkify>{textBody}</Linkify>
                  {extraContext?.link && (
                    <ChatMessageFileAttachment
                      url={extraContext.link}
                      mime_type={extraContext.type}
                    />
                  )}
                  {fileBody?.url && fileBody?.mime_type && (
                    <ChatMessageFileAttachment url={fileBody.url} mime_type={fileBody.mime_type} />
                  )}
                </StyledTextContainer>
              )}
            </Box>
          </Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: senderType === 'user' ? 'flex-end' : 'flex-start',
              mt: 1,
              px: 2,
            }}
          >
            <Typography color="textSecondary" noWrap variant="caption">
              {formatDistanceToNowStrict(createdAt)} ago
            </Typography>
          </Box>
        </div>
        {isAnyMenuActionAvailable && (
          <>
            <IconButton
              onClick={handleOpenMessageMenu}
              sx={{
                height: 32,
                mr: senderType !== 'user' || isInPinnedMessagesPopUp ? 0 : 2,
                ml: senderType !== 'user' || isInPinnedMessagesPopUp ? 2 : 0,
                width: 32,
                position: 'relative',
                top: 'calc(50% - 32px)',
                backgroundColor: menuOpen
                  ? alpha(theme.palette.primary.main, 0.5)
                  : alpha(theme.palette.primary.main, 0.3),
                '&:hover': {
                  backgroundColor: alpha(theme.palette.primary.main, 0.5),
                },
              }}
            >
              <TouchApp color={'primary'} sx={{ mt: '-4px' }} />
            </IconButton>
            <Menu open={menuOpen} anchorEl={anchorEl} onClose={handleCloseMessageMenu}>
              <MenuList>
                {extraContext?.access_uuid &&
                  !extraContext?.access_approved_at &&
                  automatedTag === 'ACCESS_REQUEST' && (
                    <MenuItem
                      sx={{
                        gap: 1,
                        '> *': {
                          color: theme.palette.primary.main,
                        },
                      }}
                      onClick={() => {
                        handleOpenGrantAccessModal(extraContext as any);
                        handleCloseMessageMenu();
                      }}
                    >
                      <LockClockIcon />
                      <Typography>Grant Access</Typography>
                    </MenuItem>
                  )}
                {action === messageActions.threadShow &&
                  extraContext?.resource_thread_uuid &&
                  extraContext?.resource_thread_uuid !== threadUuid && (
                    <MenuItem
                      sx={{
                        gap: 1,
                        '> *': {
                          color: theme.palette.primary.main,
                        },
                      }}
                      onClick={() => {
                        navigate(
                          getMessagingDetailsPath({
                            forRouting: false,
                            id: extraContext.resource_thread_uuid,
                          })
                        );
                      }}
                    >
                      <ArrowForwardIcon />
                      <Typography>Go to chat</Typography>
                    </MenuItem>
                  )}
              </MenuList>
            </Menu>
          </>
        )}
      </Box>
      {expandMedia && (
        <Lightbox large={imageBody?.url} onClose={(): void => setExpandMedia(false)} />
      )}
    </Box>
  );
};

ChatMessage.propTypes = {
  contentType: PropTypes.string.isRequired,
  createdAt: PropTypes.number.isRequired,
  senderAvatar: PropTypes.string.isRequired,
  senderName: PropTypes.string.isRequired,
  senderType: PropTypes.oneOf(['contact', 'user']),
};

export default ChatMessage;
