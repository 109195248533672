import {
  Box,
  Button,
  Card,
  CircularProgress,
  LinearProgress,
  Menu,
  Typography,
} from '@mui/material';
import { Dispatch, FC, SetStateAction, useEffect, useState } from 'react';
import PushPinIcon from '@mui/icons-material/PushPin';
import PopupState, { bindMenu, bindTrigger } from 'material-ui-popup-state';
import ChatMessage from '../../../components/dashboard/chat/ChatMessage';
import { MessagingMessage } from '../../../types/messaging_message';
import { getTime, parseISO } from 'date-fns';
import { getUserNameFromUser } from '../../../utils/messagingNames';
import { MessagingParticipant } from '../../../types/messaging_participant';
import useAuth from '../../../hooks/useAuth';
import { RequestStatus } from '../../../utils/RequestStatus';
import LoadMoreButton from '../LoadMoreButton';
import ClearIcon from '@mui/icons-material/Clear';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import useModal from '../../../hooks/useModal';
import { getMyRoles } from '../../../utils/ability';
import Scrollbar from '../../../components/Scrollbar';

interface MessagingPinnedMessagesProps {
  pinnedMessages: Array<MessagingMessage>;
  pinnedMessagesFetchStatus: string | null;
  participants: Array<MessagingParticipant>;
  handleGetThreadPinnedMessages: any;
  handleChangeMesssagePinnedStatus: (data: { is_pinned: boolean; uuid: string }) => void;
  setMessageToScrollTo: Dispatch<SetStateAction<string>>;
  isLoadMorePinnedMessagesVisible: boolean;
  onLoadMorePinnedMessagesClick: any;
  changeMessagePinnedPostFetchStatus: string | null;
  getThreadMessagesWithInterval: (params: {
    id?: string | number;
    cursor?: string;
    page_size?: string | number;
    options?: {
      dispatchedFromPinnedMessages?: boolean;
      omitGetNewThreadMessages?: boolean;
      clearCurrentMessages?: boolean;
    };
  }) => void;
  messagesFetchStatus: string | null;
  checkIfMessageLoaded: (params: { uuid: string }) => boolean;
}

const MessagingPinnedMessages: FC<MessagingPinnedMessagesProps> = (props) => {
  const {
    pinnedMessages,
    pinnedMessagesFetchStatus,
    participants,
    handleGetThreadPinnedMessages,
    handleChangeMesssagePinnedStatus,
    setMessageToScrollTo,
    isLoadMorePinnedMessagesVisible,
    onLoadMorePinnedMessagesClick,
    changeMessagePinnedPostFetchStatus,
    getThreadMessagesWithInterval,
    messagesFetchStatus,
    checkIfMessageLoaded,
  } = props;

  const { user } = useAuth();

  const [showPinSpinner, setShowPinSpinner] = useState<string>(null);

  const [scrollWhenFetchStatusDone, setScrollWhenFetchStatusDone] = useState<{
    messageUuid: string;
    shouldScroll: boolean;
  }>({ messageUuid: null, shouldScroll: false });

  const { Component: ConfirmUnPinModal, ...confirmUnPinModal } = useModal();

  const handleGoToMessage = ({
    cursor_value,
    uuid,
    handleClose,
  }: {
    cursor_value: string;
    uuid: string;
    handleClose: any;
  }): void => {
    if (checkIfMessageLoaded({ uuid })) {
      setMessageToScrollTo(uuid);
    } else {
      getThreadMessagesWithInterval({
        cursor: cursor_value,
        page_size: 20,
        options: { dispatchedFromPinnedMessages: true },
      });
      setScrollWhenFetchStatusDone({ messageUuid: uuid, shouldScroll: true });
    }
    handleClose();
  };

  useEffect(() => {
    if (!RequestStatus.isFetching(changeMessagePinnedPostFetchStatus) && showPinSpinner)
      setShowPinSpinner(null);
  }, [changeMessagePinnedPostFetchStatus]);

  useEffect(() => {
    if (RequestStatus.isDone(messagesFetchStatus) && scrollWhenFetchStatusDone.shouldScroll) {
      setMessageToScrollTo(scrollWhenFetchStatusDone.messageUuid);

      setScrollWhenFetchStatusDone({ messageUuid: null, shouldScroll: false });
    }
  }, [messagesFetchStatus, scrollWhenFetchStatusDone]);

  return (
    <PopupState variant="popover" popupId="pinned-messages-popup-menu">
      {(popupState) => (
        <>
          <Button
            variant={'text'}
            sx={{ display: 'inline-flex', alignItems: 'center' }}
            onClick={(e) => {
              handleGetThreadPinnedMessages({ cursor: null });
              bindTrigger(popupState).onClick(e);
            }}
            // {...bindTrigger(popupState)}
          >
            <PushPinIcon fontSize="small" sx={{ mr: 1 }} />
            <Typography variant={'subtitle2'}>Pinned messages</Typography>
          </Button>
          <Menu
            {...bindMenu(popupState)}
            PaperProps={{
              sx: {
                backgroundColor: (theme) => theme.palette.background.default,
                overflow: 'hidden',
              },
            }}
            MenuListProps={{
              sx: {
                py: 0,
              },
            }}
          >
            <Box sx={{ backgroundColor: 'background.paper', px: 2, py: 1.5, display: 'flex' }}>
              <PushPinIcon sx={{ color: 'primary.main', mr: 1 }} />
              <Typography variant={'subtitle1'}>Pinned Messages</Typography>
            </Box>
            {RequestStatus.isFetching(pinnedMessagesFetchStatus) && <LinearProgress />}
            <Scrollbar style={{ maxHeight: 'calc(100vh - 350px)' }}>
              {pinnedMessages.length ? (
                pinnedMessages.map((message, idx) => {
                  let participant = message.sender
                    ? participants.find(
                        (_participant) =>
                          _participant.uuid === message.sender.uuid ||
                          _participant.username === message.sender.username
                      )
                    : null;
                  if (participant && !participant.senderName) {
                    participant = {
                      ...participant,
                      senderName: getUserNameFromUser(participant),
                    };
                  }
                  if (message.sender && message.sender?.uuid === user.uuid) {
                    participant = {
                      avatar: user?.profile_image?.thumbnails?.avatar,
                      username: user.username,
                      uuid: message?.sender?.uuid,
                      senderName: 'Me',
                      senderType: 'user',
                    };
                  } else if (!participant) {
                    const name = getUserNameFromUser(message.sender);
                    participant = {
                      avatar: message.sender_avatar,
                      senderName: name,
                      username: name,
                      uuid: message?.sender?.uuid || getTime(new Date()).toString(),
                      senderType: null,
                    };
                  }
                  if (participant && message.sender_avatar && !participant.avatar)
                    participant.avatar = message.sender_avatar;

                  const isAdmin =
                    getMyRoles()?.map(
                      (role) =>
                        role.toLowerCase().includes('admin') &&
                        !role.toLowerCase().includes('vendor')
                    ).length > 0;

                  return (
                    <Card
                      key={`${message.uuid}-pinned`}
                      sx={{
                        mx: 2,
                        my: 2,
                        pt: 0.5,
                        pl: 2,
                        pr: 1,
                        pb: 0,
                        minWidth: '250px',
                        ...(idx === pinnedMessages?.length - 1 && { mb: 1 }),
                      }}
                    >
                      <ConfirmUnPinModal
                        {...confirmUnPinModal.props}
                        title={'Un-pin message'}
                        isConfirmation={true}
                        isOpen={confirmUnPinModal.isOpen && showPinSpinner === message.uuid}
                        onConfirm={() => {
                          handleChangeMesssagePinnedStatus({
                            is_pinned: false,
                            uuid: message.uuid,
                          });
                          setShowPinSpinner(message.uuid);
                          confirmUnPinModal.close();
                        }}
                        onCancel={() => {
                          confirmUnPinModal.close();
                        }}
                        confirmLabel={'Yes'}
                        cancelLabel={'No'}
                        description={'Are you sure you want to un-pin this message?'}
                      />
                      {isAdmin && (
                        <Box
                          sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            width: '100%',
                            pt: 0.5,
                          }}
                        >
                          <Button
                            variant={'text'}
                            endIcon={<ArrowForwardIcon />}
                            onClick={() => {
                              handleGoToMessage({
                                cursor_value: message.cursor_value,
                                uuid: message.uuid,
                                handleClose: popupState.close,
                              });
                            }}
                          >
                            Go to
                          </Button>
                          <Button
                            variant={'text'}
                            disabled={
                              RequestStatus.isFetching(changeMessagePinnedPostFetchStatus) &&
                              showPinSpinner === message.uuid
                            }
                            endIcon={
                              RequestStatus.isFetching(changeMessagePinnedPostFetchStatus) &&
                              showPinSpinner === message.uuid ? (
                                <CircularProgress size={20} />
                              ) : (
                                <ClearIcon />
                              )
                            }
                            onClick={() => {
                              setShowPinSpinner(message.uuid);
                              confirmUnPinModal.open();
                            }}
                          >
                            Un-pin
                          </Button>
                        </Box>
                      )}

                      <ChatMessage
                        uuid={message.uuid}
                        textBody={message.text}
                        imageBody={message.image}
                        fileBody={message.file}
                        contentType={message.image ? 'image' : 'text'}
                        createdAt={parseISO(message.created)}
                        key={`${message.uuid}-pinned`}
                        senderAvatar={participant?.avatar}
                        senderName={participant.senderName}
                        senderType={participant?.senderType}
                        sender={participant}
                        extraContext={message.extra_context}
                        boxSx={{ mb: 1 }}
                        automatedTag={message.automated_tag}
                        action={message.action}
                        pinConfig={{
                          is_pinned: message.is_pinned,
                          showPinActions: false,
                          handleChangeMesssagePinnedStatus: handleChangeMesssagePinnedStatus,
                          changeMessagePinnedPostFetchStatus: changeMessagePinnedPostFetchStatus,
                          isInPinnedMessagesPopUp: true,
                        }}
                      />
                    </Card>
                  );
                })
              ) : (
                <Typography
                  sx={{
                    px: 3,
                    py: 1,
                    backgroundColor: (theme) => theme.palette.background.paper,
                  }}
                  onClick={popupState.close}
                  variant={'subtitle1'}
                  align={'center'}
                >
                  No pinned messages
                </Typography>
              )}
              {pinnedMessages.length > 0 && (
                <LoadMoreButton
                  onClick={onLoadMorePinnedMessagesClick}
                  isVisible={isLoadMorePinnedMessagesVisible}
                  disabled={RequestStatus.isFetching(pinnedMessagesFetchStatus)}
                />
              )}
            </Scrollbar>
          </Menu>
        </>
      )}
    </PopupState>
  );
};

export default MessagingPinnedMessages;
