import { useEffect } from 'react';
import type { FC } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Avatar, Badge, Box, Button, Divider, Drawer, Link, Typography } from '@mui/material';
import type { Theme } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import useAuth from '../../hooks/useAuth';
import Logo from '../../components/Logo';
import NavSection from '../../components/NavSection';
import Scrollbar from '../../components/Scrollbar';
import { getMenuRoutes } from '../../routesForSidebar';
import { ability, permissions } from '../../utils/Can';
import { ORGANIZATION_MESSAGES, VENDOR_MESSAGES } from '../../utils/permissions.constants';
const packageJ = require('../../../package.json');
interface DashboardSidebarProps {
  onMobileClose: () => void;
  openMobile: boolean;
  messagesCount?: number;
}

const _sections = getMenuRoutes();
const getRoutesToHideByEnv = () => {
  const subjectsStringToHide = process.env.REACT_APP_HIDE_ROUTES;
  let subjectsToHide = [];
  if (subjectsStringToHide?.length > 2) {
    try {
      subjectsToHide = JSON.parse(subjectsStringToHide);
    } catch (e) {
      subjectsToHide = [];
    }
  }
  return subjectsToHide;
};

export const filterMenuRoutesToShow = (sections) => {
  const hideByEnv = getRoutesToHideByEnv();
  let routes =
    sections[0]?.items?.filter((route) => {
      if (route.permissionSubject && route.permissionSubject.includes('MESSAGES')) {
        return (
          !hideByEnv.includes(route.permissionSubject) &&
          (!route.permissionSubject ||
            (route.permissionSubject &&
              (ability.can(permissions.READ, route.permissionSubject) ||
                ability.can(permissions.READ, ORGANIZATION_MESSAGES) ||
                ability.can(permissions.READ, VENDOR_MESSAGES))))
        );
      } else
        return (
          !hideByEnv.includes(route.permissionSubject) &&
          (!route.permissionSubject ||
            (route.permissionSubject && ability.can(permissions.READ, route.permissionSubject)))
        );
    }) || [];
  return [{ ...sections[0], items: routes }];
};

const DashboardSidebar: FC<DashboardSidebarProps> = (props) => {
  const { onMobileClose, openMobile, ...otherProps } = props;
  const location = useLocation();
  const { user } = useAuth();
  const drawerVisible = useMediaQuery((theme: Theme) => theme.breakpoints.up('xs'));

  const version = `v${packageJ.version}`;

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
  }, [location.pathname]);

  const sectionsCanAccess = filterMenuRoutesToShow(_sections);

  const content = (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        height: '100%',
      }}
    >
      <Scrollbar options={{ suppressScrollX: true }}>
        <Box
          sx={{
            display: {
              lg: 'none',
              xs: 'none',
            },
            justifyContent: 'center',
            p: 2,
          }}
        >
          <RouterLink to="/">
            <Logo
              sx={{
                height: 41,
                width: 150,
              }}
            />
          </RouterLink>
        </Box>
        <Box sx={{ p: 2 }}>
          {sectionsCanAccess.map((section) => (
            <NavSection
              key={section.title}
              pathname={location.pathname}
              sx={{
                '& + &': {
                  mt: 3,
                },
              }}
              {...otherProps}
              {...section}
            />
          ))}
        </Box>
        <Divider />
      </Scrollbar>
      <Typography
        variant={'subtitle2'}
        color={'textSecondary'}
        align={'center'}
        sx={{ padding: '8px' }}
      >
        {version}
      </Typography>
    </Box>
  );

  if (drawerVisible) {
    return (
      <Drawer
        anchor="left"
        open
        PaperProps={{
          sx: {
            backgroundColor: 'background.paper',
            height: 'calc(100% - 64px) !important',
            top: '64px !Important',
            width: 280,
          },
        }}
        variant="permanent"
      >
        {content}
      </Drawer>
    );
  }

  return (
    <Drawer
      anchor="left"
      onClose={onMobileClose}
      open={openMobile}
      PaperProps={{
        sx: {
          backgroundColor: 'background.paper',
          width: 280,
        },
      }}
      variant="temporary"
    >
      {content}
    </Drawer>
  );
};

DashboardSidebar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool,
};

export default DashboardSidebar;
