import { Card, Link, Typography } from '@mui/material';
import { FC } from 'react';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import { getFileNameFromLink } from '../../../containers/Messaging/messagingHelper';

interface ChatMessageFileAttachmentProps {
  url: string;
  mime_type?: string;
}

const ChatMessageFileAttachment: FC<ChatMessageFileAttachmentProps> = (props) => {
  const { url, mime_type } = props;

  return (
    <Card
      variant={'outlined'}
      sx={{
        display: 'flex',
        alignItems: 'center',
        px: 1,
        py: 1,
        mt: 1,
      }}
    >
      <AttachFileIcon sx={{ mr: 0.5, color: 'text.secondary' }} />
      <Link
        href={url}
        rel={'noopener'}
        {...(!mime_type?.includes('pdf') ? { download: url } : { target: '_blank' })}
      >
        <Typography sx={{ wordBreak: 'break-word', color: 'text.secondary' }}>
          {getFileNameFromLink(url)}
        </Typography>
      </Link>
    </Card>
  );
};

export default ChatMessageFileAttachment;
