// from original Material Theme
// import firebase from 'firebase/app';
// import 'firebase/auth';
// import { firebaseConfig } from '../config';
// if (!firebase.apps.length) {
//   firebase.initializeApp(firebaseConfig);
// }

import firebase from 'firebase';

let wasInitialized = false;
let wasErrorDuringFCMInitialize = false;
let wasErrorDuringSocketInitialize = false;

export const setWasErrorDuringFCMInitialize = (value) => (wasErrorDuringFCMInitialize = value);
export const setWasErrorDuringSocketInitialize = (value) =>
  (wasErrorDuringSocketInitialize = value);

export const isFCMChannelSupported = () => {
  return (
    typeof BroadcastChannel !== 'undefined' &&
    firebase.messaging.isSupported() &&
    !wasErrorDuringFCMInitialize
  );
};

export const initializeFirebase = () => {
  if (wasInitialized) return;
  wasInitialized = true;
  console.log('Firebase initialized.');
  firebase.initializeApp({
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_FIREBASE_DB_URL,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
    measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
  });

  if (process.env.REACT_APP_FIREBASE_MEASUREMENT_ID) {
    firebase.analytics();
  }
};

export const getMessagingObject = () => firebase.messaging();

export function receiveMessage() {
  return new Promise((resolve, reject) => {
    if (!wasInitialized) throw 'Firebase not initialized';
    const messaging = firebase.messaging();
    console.log('Main thread registered');
    // [START messaging_receive_message]
    // Handle incoming messages. Called when:
    // - a message is received while the app has focus
    // - the user clicks on an app notification created by a service worker
    //   `messaging.onBackgroundMessage` handler.
    console.log('On message initialized');
    messaging.onMessage((payload) => {
      console.log('Message received. ', payload);
      resolve(payload);
      // ...
    });
    // [END messaging_receive_message]
  });
}

export const getToken = async () => {
  const messaging = firebase.messaging();
  return messaging.getToken({ vapidKey: process.env.REACT_APP_FIREBASE_VAPID_KEY });
};

// export function _getToken() {
//   const messaging = firebase.messaging();
//   // [START messaging_get_token]
//   // Get registration token. Initially this makes a network call, once retrieved
//   // subsequent calls to getToken will return from cache.
//   messaging
//     .getToken({ vapidKey: process.env.REACT_APP_FIREBASE_VAPID_KEY })
//     .then((currentToken) => {
//       if (currentToken) {
//         // Send the token to your server and update the UI if necessary
//         // ...
//       } else {
//         // Show permission request UI
//         console.log('No registration token available. Request permission to generate one.');
//         // ...
//       }
//     })
//     .catch((err) => {
//       console.log('An error occurred while retrieving token. ', err);
//       // ...
//     });
//   // [END messaging_get_token]
// }

export async function requestPermission() {
  // [START messaging_request_permission]
  return new Promise((resolve, reject) => {
    try {
      Notification.requestPermission().then((permission) => {
        if (permission === 'granted') {
          console.log('Notification permission granted.');
          // TODO(developer): Retrieve a registration token for use with FCM.
          resolve(permission);
        } else {
          console.log('Unable to get permission to notify.');
          reject();
        }
      });
    } catch (error) {
      // Safari doesn't return a promise for requestPermissions and it
      // throws a TypeError. It takes a callback as the first argument
      // instead.
      if (typeof Notification !== 'undefined' && error instanceof TypeError) {
        Notification.requestPermission((permission) => {
          if (permission === 'granted') {
            console.log('Notification permission granted.');
            resolve(permission);
          } else {
            console.log('Unable to get permission to notify.');
            reject();
          }
        });
      } else {
        throw error;
      }
    }
  });
  // [END messaging_request_permission]
}

export function deleteFCMToken() {
  if (!isFCMChannelSupported()) return;
  const messaging = firebase.messaging();

  // [START messaging_delete_token]
  messaging
    .deleteToken()
    .then(() => {
      console.log('FCM token deleted.');
      // ...
    })
    .catch((err) => {
      console.log('Unable to delete FCM token. ', err);
    });
  // [END messaging_delete_token]
}

// from original Material Theme
export default firebase;
