import React, { ChangeEvent, FC, useEffect, useRef, useState } from 'react';
import {
  Box,
  Button,
  Card,
  CircularProgress,
  Divider,
  IconButton,
  LinearProgress,
  Typography,
} from '@mui/material';
import ScheduleSendIcon from '@mui/icons-material/ScheduleSend';
import useModal, { SimpleActions } from '../../../hooks/useModal';
import { useFieldArray, useForm } from 'react-hook-form';
import { Input } from '../../../components/widgets/inputs/Input';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import { Lightbox } from 'react-modal-image';
import { useTheme } from '@mui/material/styles';
import ClearIcon from '@mui/icons-material/Clear';
import ChatMessage from '../../../components/dashboard/chat/ChatMessage';
import Scrollbar from '../../../components/Scrollbar';
import AddIcon from '@mui/icons-material/Add';
import { postFile } from '../../../slices/fileUpload';
import { RootState, useDispatch, useSelector } from '../../../store';
import toast from 'react-hot-toast';
import {
  getScheduledMessageDetails,
  patchScheduledMessage,
  postScheduledMessage,
  resetGetScheduledMessageDetails,
  resetPatchScheduledMessageStatus,
  resetPostScheduledMessageStatus,
  ScheduledMessageType,
} from '../../../slices/messaging';
import { useParams } from 'react-router-dom';
import { RequestStatus } from '../../../utils/RequestStatus';
import { uniq } from 'lodash';

interface FormValuesProps {
  name: string;
  text: string;
  sending_rules: Array<{ start_sending_at: Date | null; repeat_every: string }>;
}

interface ScheduledMessageModalProps {
  isEdit?: boolean;
  openEditFor?: ScheduledMessageType | null;
  closeEditMode?: () => void;
}

const initialFormValues: FormValuesProps = {
  name: '',
  text: '',
  sending_rules: [{ start_sending_at: new Date(), repeat_every: 'never' }],
};

const frequencyOptions: Array<{ value: string | number; label: string }> = [
  { value: 'never', label: 'One time' },
  { value: 'daily', label: 'Every day' },
  { value: 'weekly', label: 'Every week' },
  { value: 'monthly', label: 'Every month' },
  { value: 'yearly', label: 'Every year' },
];

const SCHEDULED_MESSAGE_FORM_ID = 'SCHEDULED_MESSAGE_FORM_ID';
const SCHEDULED_MESSAGE_STORE_KEY = 'SCHEDULED_MESSAGE_STORE_KEY';

const ScheduledMessageModal: FC<ScheduledMessageModalProps> = (props) => {
  const { isEdit = false, openEditFor = null, closeEditMode = null } = props;

  const dispatch = useDispatch();
  const theme = useTheme();
  const { uuid } = useParams();

  const { control, handleSubmit, watch, reset, getValues, setValue } = useForm<FormValuesProps>({
    defaultValues: initialFormValues,
  });
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'sending_rules',
  });

  const { fetchStatus, error } = useSelector(
    (state: RootState) => state.messaging.thread.scheduledMessage.post
  );
  const { fetchStatus: patchFetchStatus, error: patchError } = useSelector(
    (state: RootState) => state.messaging.thread.scheduledMessage.patch
  );
  const { fetchStatus: detailsFetchStatus, message } = useSelector(
    (state: RootState) => state.messaging.thread.scheduledMessage.details
  );

  const { files } = useSelector((state: RootState) => state.fileUpload);

  const nameContent = watch('name');

  const { Component: ScheduledMessageModal, ...scheduledMessageModal } = useModal();
  const { Component: ConfirmModal, ...confirmModal } = useModal();

  const [attachment, setAttachment] = useState<
    File | { url: string; size: number; name: string; mime_type: string } | null
  >(null);
  const [image, setImage] = useState<any>(null);
  const [expandMedia, setExpandMedia] = useState<boolean>(false);

  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const handleAttach = (): void => {
    fileInputRef.current.click();
  };

  const getDataUrl = (file) => {
    const reader = new FileReader();
    return new Promise((resolve) => {
      reader.onload = (e) => {
        resolve({
          image: e.target.result,
          name: file.name,
        });
      };
      reader.readAsDataURL(file);
    });
  };

  const handleAttachmentAdd = async (
    event: ChangeEvent<HTMLInputElement> | { target: { files } }
  ): Promise<void> => {
    const files = event.target.files;
    if (files.length > 0) {
      const file = files[0];
      if (file.type.includes('image')) {
        const image = await getDataUrl(file);
        setImage({ image, file });
        setAttachment(null);
      } else {
        setAttachment(file);
        setImage(null);
      }
    }
    // else {
    //   setAttachment(null);
    //   setImage(null);
    // }
  };

  const onSubmit = async (_values: FormValuesProps) => {
    const file = image?.file || (attachment instanceof File ? attachment : null) || null;
    const type = file?.type;
    let fileUuid = null;
    if (file) {
      await dispatch(
        postFile({
          file,
          type,
          storeKey: SCHEDULED_MESSAGE_STORE_KEY,
        })
      )
        .then((response) => {
          fileUuid = response?.payload?.results[0]?.payload?.uuid;
        })
        .catch((err) => {
          toast.error('Error uploading file');
          return;
        });
    }
    const values = {
      ...Object.fromEntries(
        Object.entries(_values).map(([key, value]) =>
          Array.isArray(value)
            ? [
                key,
                value.map((v: { start_sending_at: Date | null; repeat_every: string }) => ({
                  start_sending_at:
                    typeof v.start_sending_at?.getMonth === 'function'
                      ? v.start_sending_at.toISOString()
                      : null,
                  repeat_every: v.repeat_every === 'never' ? null : v.repeat_every,
                })),
              ]
            : [key, value.length === 0 ? null : value]
        )
      ),
      ...(file
        ? type.includes('image')
          ? { image: fileUuid, file: null }
          : { file: fileUuid, image: null }
        : isEdit
        ? {}
        : { file: null, image: null }),
      thread: uuid,
      ...(isEdit && {
        uuid: message.uuid,
      }),
    };

    if (isEdit) dispatch(patchScheduledMessage(values));
    else dispatch(postScheduledMessage(values));
  };

  const handleCancelScheduleModal = (): void => {
    scheduledMessageModal.close();
    isEdit && closeEditMode();
    setTimeout(() => {
      reset(initialFormValues);
      setImage(null);
      setAttachment(null);
    }, 150);
  };

  const handleCancelConfirmModal = (): void => {
    confirmModal.close();
    isEdit && closeEditMode();
    setTimeout(() => {
      reset(initialFormValues);
      setImage(null);
      setAttachment(null);
    }, 150);
  };

  useEffect(() => {
    if (!isEdit) return;
    if (openEditFor) {
      scheduledMessageModal.open();
      dispatch(
        getScheduledMessageDetails({
          uuid: openEditFor.uuid,
        })
      );
    } else {
      scheduledMessageModal.close();
      confirmModal.close();
      setTimeout(() => {
        dispatch(resetGetScheduledMessageDetails({}));
      }, 150);
    }
  }, [openEditFor]);

  useEffect(() => {
    if (!isEdit) return;
    if (RequestStatus.isDone(detailsFetchStatus)) {
      reset({
        name: message.name,
        text: message.text,
        sending_rules: message.sending_rules.map(
          (rule: { uuid: string; start_sending_at: string; repeat_every: string }) => ({
            ...rule,
            start_sending_at: rule.start_sending_at ? new Date(rule.start_sending_at) : null,
            repeat_every: rule.repeat_every === null ? 'never' : rule.repeat_every,
          })
        ),
      });
      if (message.file) setAttachment(message.file);
      if (message.image) setImage({ image: { image: message.image.url } });
    }
    if (RequestStatus.isError(detailsFetchStatus))
      toast.error("There was an error fetching scheduled message's details");
  }, [detailsFetchStatus]);

  useEffect(() => {
    if (isEdit) return;
    if (RequestStatus.isDone(fetchStatus)) {
      toast.success(`Scheduled message created!`);
      dispatch(resetPostScheduledMessageStatus({}));
      handleCancelConfirmModal();
    } else if (RequestStatus.isError(fetchStatus)) {
      const messages = Object.values(error).map((err: Array<string>) => err[0]) ?? [];
      let message = uniq(messages).join('. ');
      if (message.includes("'text'")) message = message.replaceAll("'text'", "'Message'");
      if (message.includes("'file', 'image'"))
        message = message.replaceAll("'file', 'image'", "'Attachment'");
      toast.error(message);
      dispatch(resetPostScheduledMessageStatus({}));
      confirmModal.close();
      setTimeout(() => scheduledMessageModal.open(), 150);
    }
  }, [fetchStatus]);

  useEffect(() => {
    if (!isEdit) return;
    if (RequestStatus.isDone(patchFetchStatus)) {
      toast.success(`Scheduled message edited!`);
      dispatch(resetPatchScheduledMessageStatus({}));
      handleCancelConfirmModal();
    } else if (RequestStatus.isError(patchFetchStatus)) {
      const messages = Object.values(patchError).map((_err: Array<string>) => _err[0]) ?? [];
      let message = uniq(messages).join('. ');
      if (message.includes("'text'")) message = message.replaceAll("'text'", "'Message'");
      if (message.includes("'file', 'image'"))
        message = message.replaceAll("'file', 'image'", "'Attachment'");
      toast.error(message);
      dispatch(resetPatchScheduledMessageStatus({}));
      confirmModal.close();
      setTimeout(() => scheduledMessageModal.open(), 150);
    }
  }, [patchFetchStatus]);

  return (
    <>
      <ScheduledMessageModal
        {...scheduledMessageModal.props}
        handleClose={handleCancelScheduleModal}
        sx={{
          width: 'fit-content',
          height: 'fit-content',
          minWidth: '700px',
          maxWidth: '700px',
          overflow: 'auto',
        }}
      >
        {isEdit && (
          <Box
            sx={{
              height: '4px',
              mt: '-40px',
              mb: '40px',
              ml: '-42px',
              width: 'calc(100% + 84px)',
            }}
          >
            {RequestStatus.isFetching(detailsFetchStatus) && <LinearProgress />}
          </Box>
        )}
        <ScheduleSendIcon color={'primary'} sx={{ height: '64px', width: '64px' }} />
        <Typography color="textPrimary" variant="h5" sx={{ marginBottom: '20px' }}>
          Scheduled message
        </Typography>
        {/*<Scrollbar*/}
        {/*  style={{*/}
        {/*    paddingTop: '8px',*/}
        {/*    paddingBottom: '8px',*/}
        {/*    height: '500px',*/}
        {/*    paddingRight: '16px',*/}
        {/*  }}*/}
        {/*>*/}
        <form id={SCHEDULED_MESSAGE_FORM_ID} onSubmit={handleSubmit(onSubmit)}>
          <Input
            type={'text'}
            name={'name'}
            isEditMode
            control={control}
            sx={{
              maxWidth: 'unset',
              maxHeight: 'unset',
              '&>div': {
                height: 'unset',
              },
              mb: 2,
            }}
            label={'Name'}
            shrinkLabel
            placeholder={'Enter name of new scheduled message here'}
          />
          <Input
            type={'text'}
            name={'text'}
            isEditMode
            control={control}
            multiline
            rows={3}
            sx={{
              maxWidth: 'unset',
              maxHeight: 'unset',
              '&>div': {
                height: 'unset',
              },
            }}
            label={'Message'}
            shrinkLabel
            placeholder={'Enter your message here'}
          />
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              // justifyContent: 'space-between',
              my: 1,
              gap: 3,
              minHeight: '64px',
            }}
          >
            <Button
              variant={'contained'}
              startIcon={<AttachFileIcon />}
              onClick={handleAttach}
              sx={{
                minWidth: '180px',
              }}
            >
              Select attachment
            </Button>

            <Box
              sx={{
                display: 'flex',
                gap: 0.5,
                alignItems: 'center',
              }}
            >
              {image?.image?.image && (
                <img
                  onClick={(): void => setExpandMedia(true)}
                  src={image.image.image}
                  alt={'attachment'}
                  style={{
                    height: '64px',
                    width: '64px',
                    objectFit: 'contain',
                    borderRadius: '8px',
                    border: `solid 1px ${theme.palette.divider}`,
                    cursor: 'pointer',
                  }}
                />
              )}
              {attachment && (
                <Card
                  variant={'outlined'}
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    px: 1,
                    // py: 1,
                    // mt: 1,
                  }}
                >
                  {/*<AttachFileIcon sx={{ mr: 0.5, color: 'text.secondary' }} />*/}
                  <Typography
                    sx={{
                      wordBreak: 'break-word',
                      color: 'text.secondary',
                      display: '-webkit-box',
                      WebkitBoxOrient: 'vertical',
                      WebkitLineClamp: 2,
                    }}
                  >
                    {attachment.name}
                  </Typography>
                </Card>
              )}
              {(image?.image?.image || attachment) && (
                <IconButton
                  onClick={() => {
                    setImage(null);
                    setAttachment(null);
                  }}
                  color={'error'}
                >
                  <ClearIcon />
                </IconButton>
              )}
            </Box>
          </Box>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: 3,
              mb: 2,
            }}
          >
            <Input
              type={'datetime'}
              minDate={isEdit ? null : new Date()}
              isEditMode
              control={control}
              name={`sending_rules.${0}.start_sending_at`}
              label={'Starting date'}
            />
            <Input
              type={'dropdown'}
              isEditMode
              control={control}
              name={`sending_rules.${0}.repeat_every`}
              label={'Frequency'}
              choices={frequencyOptions}
            />
          </Box>
          <Divider />
          <Typography variant={'h6'} sx={{ my: 1 }}>
            Additional days to send a message:
          </Typography>
          {fields.length > 1 ? (
            fields.map((field, idx) =>
              idx === 0 ? (
                <></>
              ) : (
                <Box
                  sx={{
                    display: 'flex',
                    gap: 1,
                    my: 1.5,
                  }}
                >
                  <Input
                    type={'datetime'}
                    minDate={isEdit ? null : new Date()}
                    isEditMode
                    control={control}
                    name={`sending_rules.${idx}.start_sending_at`}
                    label={'Date'}
                  />
                  <Input
                    type={'dropdown'}
                    isEditMode
                    control={control}
                    name={`sending_rules.${idx}.repeat_every`}
                    label={'Frequency'}
                    choices={frequencyOptions}
                  />
                  <IconButton
                    onClick={() => {
                      remove(idx);
                    }}
                    color={'error'}
                  >
                    <ClearIcon />
                  </IconButton>
                </Box>
              )
            )
          ) : (
            <Typography variant={'h6'} sx={{ my: 1 }} color={'textSecondary'}>
              No additional days added
            </Typography>
          )}
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <Button
              onClick={() => append({ start_sending_at: new Date(), repeat_every: 'never' })}
              variant={'contained'}
              startIcon={<AddIcon />}
            >
              Add another
            </Button>
          </Box>
        </form>
        {/*</Scrollbar>*/}
        <SimpleActions
          sx={{ mt: 2 }}
          confirmLabel={'Preview'}
          // form={SCHEDULED_MESSAGE_FORM_ID}
          onConfirm={() => {
            scheduledMessageModal.close();
            setTimeout(() => confirmModal.open(), 150);
          }}
          onCancel={handleCancelScheduleModal}
          disableConfirm={nameContent.length === 0 || RequestStatus.isFetching(fetchStatus)}
          loading={RequestStatus.isFetching(fetchStatus)}
        />
      </ScheduledMessageModal>
      <ConfirmModal
        {...confirmModal.props}
        handleClose={handleCancelConfirmModal}
        sx={{
          width: 'fit-content',
          height: 'fit-content',
          minWidth: '700px',
          maxWidth: '700px',
          overflow: 'auto',
        }}
      >
        <ScheduleSendIcon color={'primary'} sx={{ height: '64px', width: '64px' }} />
        <Typography color="textPrimary" variant="h5" sx={{ marginBottom: '20px' }}>
          Preview scheduled message
        </Typography>
        {/*<Scrollbar*/}
        {/*  style={{*/}
        {/*    height: '400px',*/}
        {/*    margin: '16px 0 16px 0',*/}
        {/*  }}*/}
        {/*>*/}
        {getValues('text').length > 0 && (
          <ChatMessage
            uuid={''}
            textBody={getValues('text')}
            contentType={'text'}
            createdAt={Date.now()}
            senderName={''}
            senderType={'contact'}
            sender={{
              uuid: '',
              username: '',
            }}
            extraContext={null}
            automatedTag={''}
            action={''}
            pinConfig={{
              is_pinned: false,
              showPinActions: false,
            }}
            hideFingerMenu={true}
          />
        )}
        {image?.image?.image && (
          <ChatMessage
            uuid={''}
            imageBody={{
              uuid: '',
              url: image.image.image,
              thumbnails: {
                thumb: image.image.image,
                avatar: image.image.image,
              },
            }}
            contentType={'image'}
            createdAt={Date.now()}
            senderName={''}
            senderType={'contact'}
            sender={{
              uuid: '',
              username: '',
            }}
            extraContext={null}
            automatedTag={''}
            action={''}
            pinConfig={{
              is_pinned: false,
              showPinActions: false,
            }}
            hideFingerMenu={true}
          />
        )}
        {attachment && (
          <ChatMessage
            uuid={''}
            contentType={'text'}
            createdAt={Date.now()}
            senderName={''}
            senderType={'contact'}
            sender={{
              uuid: '',
              username: '',
            }}
            extraContext={{
              type: attachment instanceof File ? attachment.type : attachment?.mime_type,
              link: attachment.name,
            }}
            automatedTag={''}
            action={''}
            pinConfig={{
              is_pinned: false,
              showPinActions: false,
            }}
            hideFingerMenu={true}
          />
        )}
        {/*</Scrollbar>*/}
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            gap: '132px',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              gap: 1,
            }}
          >
            <Button
              type="button"
              onClick={handleCancelConfirmModal}
              color="primary"
              sx={{
                m: 1,
                margin: 0,
                minWidth: '160px',
                py: 1,
                fontSize: '16px',
              }}
              variant="outlined"
            >
              Cancel
            </Button>
            <Button
              type="button"
              onClick={() => {
                confirmModal.close();
                setTimeout(() => scheduledMessageModal.open(), 150);
              }}
              color="primary"
              sx={{
                m: 1,
                margin: 0,
                minWidth: '160px',
                py: 1,
                fontSize: '16px',
              }}
              variant="outlined"
            >
              Back
            </Button>
          </Box>
          <Button
            // type={'submit'}
            form={SCHEDULED_MESSAGE_FORM_ID}
            sx={{
              m: 1,
              minWidth: '160px',
              margin: 0,
              py: 1,
              fontSize: '16px',
            }}
            variant="contained"
            onClick={() => onSubmit(getValues())}
            disabled={
              RequestStatus.isFetching(fetchStatus) ||
              RequestStatus.isFetching(patchFetchStatus) ||
              RequestStatus.isFetching(files?.[SCHEDULED_MESSAGE_STORE_KEY]?.fetchStatus)
            }
          >
            Submit
            {(RequestStatus.isFetching(fetchStatus) ||
              RequestStatus.isFetching(patchFetchStatus) ||
              RequestStatus.isFetching(files?.[SCHEDULED_MESSAGE_STORE_KEY]?.fetchStatus)) && (
              <CircularProgress size={16} sx={{ ml: 1, color: 'white' }} />
            )}
          </Button>
        </Box>
      </ConfirmModal>
      {!isEdit && (
        <Button
          variant={'text'}
          color={'primary'}
          startIcon={<ScheduleSendIcon />}
          onClick={() => scheduledMessageModal.open()}
        >
          Scheduled Message
        </Button>
      )}
      {expandMedia && image?.image?.image && (
        <Lightbox large={image?.image?.image} onClose={(): void => setExpandMedia(false)} />
      )}
      <input hidden ref={fileInputRef} type="file" onChange={handleAttachmentAdd} />
    </>
  );
};

export default ScheduledMessageModal;
