import React, { ChangeEvent, FC, useEffect, useRef, useState } from 'react';
import CampaignIcon from '@mui/icons-material/Campaign';
import { Box, Button, Card, CircularProgress, IconButton, Typography } from '@mui/material';
import { SxProps } from '@mui/system';
import useModal, { SimpleActions } from '../../hooks/useModal';
import { RootState, useDispatch, useSelector } from '../../store';
import { useForm } from 'react-hook-form';
import { postFile } from '../../slices/fileUpload';
import toast from 'react-hot-toast';
import { postBlastMessage, resetPostBlastMessage } from '../../slices/messaging';
import { Input } from '../../components/widgets/inputs/Input';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import ClearIcon from '@mui/icons-material/Clear';
import { useTheme } from '@mui/material/styles';
import { RequestStatus } from '../../utils/RequestStatus';
import ScheduleSendIcon from '@mui/icons-material/ScheduleSend';
import ChatMessage from '../../components/dashboard/chat/ChatMessage';
import { Lightbox } from 'react-modal-image';
import { uniq } from 'lodash';

interface BlastMessageModalProps {
  buttonFullWidth?: boolean;
  buttonSx?: SxProps;
}

interface FormValuesProps {
  name: string;
  text: string;
  audience: string;
}

interface AudienceChoiceType {
  value: 'all' | 'technicians' | 'customers';
  label: string;
}

const initialFormValues: FormValuesProps = {
  name: '',
  text: '',
  audience: 'all',
};

const audienceChoices: Array<AudienceChoiceType> = [
  {
    value: 'all',
    label: 'All',
  },
  {
    value: 'technicians',
    label: 'Technicians',
  },
  {
    value: 'customers',
    label: 'Customers',
  },
];

const BLAST_MESSAGE_FORM_ID = 'BLAST_MESSAGE_FORM_ID';
const BLAST_MESSAGE_STORE_KEY = 'BLAST_MESSAGE_STORE_KEY';

const BlastMessageModal: FC<BlastMessageModalProps> = (props) => {
  const { buttonFullWidth = false, buttonSx = {} } = props;

  const dispatch = useDispatch();
  const theme = useTheme();

  const { control, handleSubmit, watch, reset, getValues, setValue } = useForm<FormValuesProps>({
    defaultValues: initialFormValues,
  });

  const { fetchStatus, errors } = useSelector(
    (state: RootState) => state.messaging.thread.blastMessages.post
  );

  const { files } = useSelector((state: RootState) => state.fileUpload);

  const [attachment, setAttachment] = useState<File | null>(null);
  const [image, setImage] = useState<any>(null);
  const [expandMedia, setExpandMedia] = useState<boolean>(false);

  const { Component: BlastMessageModal, ...blastMessageModal } = useModal();
  const { Component: ConfirmModal, ...confirmModal } = useModal();

  const nameContent = watch('name');

  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const handleAttach = (): void => {
    fileInputRef.current.click();
  };

  const getDataUrl = (file) => {
    const reader = new FileReader();
    return new Promise((resolve) => {
      reader.onload = (e) => {
        resolve({
          image: e.target.result,
          name: file.name,
        });
      };
      reader.readAsDataURL(file);
    });
  };

  const handleAttachmentAdd = async (
    event: ChangeEvent<HTMLInputElement> | { target: { files } }
  ): Promise<void> => {
    const files = event.target.files;
    if (files.length > 0) {
      const file = files[0];
      if (file.type.includes('image')) {
        const image = await getDataUrl(file);
        setImage({ image, file });
        setAttachment(null);
      } else {
        setAttachment(file);
        setImage(null);
      }
    }
    // else {
    //   setAttachment(null);
    //   setImage(null);
    // }
  };

  const onSubmit = async (_values: FormValuesProps) => {
    const file = image?.file || attachment || null;
    const type = file?.type;
    let fileUuid = null;
    if (file) {
      await dispatch(
        postFile({
          file,
          type,
          storeKey: BLAST_MESSAGE_STORE_KEY,
        })
      )
        .then((response) => {
          fileUuid = response?.payload?.results[0]?.payload?.uuid;
        })
        .catch((err) => {
          console.error(err);
          toast.error('Error uploading file');
          return;
        });
    }
    const values = {
      ..._values,
      ...(file
        ? type.includes('image')
          ? { image: fileUuid, file: null }
          : { file: fileUuid, image: null }
        : { file: null, image: null }),
    };

    dispatch(postBlastMessage(values));
  };

  const handleCancelBlastMessageModal = (): void => {
    blastMessageModal.close();
    setTimeout(() => {
      reset(initialFormValues);
      setImage(null);
      setAttachment(null);
    }, 150);
  };

  const handleCancelConfirmModal = (): void => {
    confirmModal.close();
    setTimeout(() => {
      reset(initialFormValues);
      setImage(null);
      setAttachment(null);
    }, 150);
  };

  useEffect(() => {
    if (RequestStatus.isDone(fetchStatus)) {
      toast.success('Blast message sent!');
      dispatch(resetPostBlastMessage({}));
      handleCancelConfirmModal();
    } else if (RequestStatus.isError(fetchStatus)) {
      // toast.error('There was an error.');
      const messages = Object.values(errors).map((err: Array<string>) => err[0]) ?? [];
      let message = uniq(messages).join('. ');
      if (message.includes("'text'")) message = message.replaceAll("'text'", "'Message'");
      if (message.includes("'file', 'image'"))
        message = message.replaceAll("'file', 'image'", "'Attachment'");
      toast.error(message);
      dispatch(resetPostBlastMessage({}));
      confirmModal.close();
      setTimeout(() => blastMessageModal.open(), 150);
    }
  }, [fetchStatus]);

  return (
    <>
      <BlastMessageModal
        {...blastMessageModal.props}
        handleClose={handleCancelBlastMessageModal}
        sx={{
          width: 'fit-content',
          height: 'fit-content',
          minWidth: '700px',
          maxWidth: '700px',
          overflow: 'auto',
        }}
      >
        <CampaignIcon color={'primary'} sx={{ height: '64px', width: '64px' }} />
        <Typography color="textPrimary" variant="h5" sx={{ marginBottom: '20px' }}>
          Blast message
        </Typography>
        <form id={BLAST_MESSAGE_FORM_ID} onSubmit={handleSubmit(onSubmit)}>
          <Input
            type={'text'}
            name={'name'}
            isEditMode
            control={control}
            sx={{
              maxWidth: 'unset',
              maxHeight: 'unset',
              '&>div': {
                height: 'unset',
              },
              mb: 2,
            }}
            label={'Name'}
            shrinkLabel
            placeholder={'Enter name of new blast message here'}
          />
          <Typography variant={'subtitle1'}>Recipients:</Typography>
          <Input
            type={'radio-group'}
            name={'audience'}
            isEditMode
            control={control}
            // label={'Audience'}
            choices={audienceChoices}
          />
          <Input
            type={'text'}
            name={'text'}
            isEditMode
            control={control}
            multiline
            rows={3}
            sx={{
              maxWidth: 'unset',
              maxHeight: 'unset',
              '&>div': {
                height: 'unset',
              },
              mt: 2,
            }}
            label={'Message'}
            shrinkLabel
            placeholder={'Enter your message here'}
          />
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              // justifyContent: 'space-between',
              my: 1,
              gap: 3,
              minHeight: '64px',
            }}
          >
            <Button
              variant={'contained'}
              startIcon={<AttachFileIcon />}
              onClick={handleAttach}
              sx={{
                minWidth: '180px',
              }}
            >
              Select attachment
            </Button>

            <Box
              sx={{
                display: 'flex',
                gap: 0.5,
                alignItems: 'center',
              }}
            >
              {image?.image?.image && (
                <img
                  onClick={(): void => setExpandMedia(true)}
                  src={image.image.image}
                  alt={'attachment'}
                  style={{
                    height: '64px',
                    width: '64px',
                    objectFit: 'contain',
                    borderRadius: '8px',
                    border: `solid 1px ${theme.palette.divider}`,
                    cursor: 'pointer',
                  }}
                />
              )}
              {attachment && (
                <Card
                  variant={'outlined'}
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    px: 1,
                    // py: 1,
                    // mt: 1,
                  }}
                >
                  {/*<AttachFileIcon sx={{ mr: 0.5, color: 'text.secondary' }} />*/}
                  <Typography
                    sx={{
                      wordBreak: 'break-word',
                      color: 'text.secondary',
                      display: '-webkit-box',
                      WebkitBoxOrient: 'vertical',
                      WebkitLineClamp: 2,
                    }}
                  >
                    {attachment.name}
                  </Typography>
                </Card>
              )}
              {(image?.image?.image || attachment) && (
                <IconButton
                  onClick={() => {
                    setImage(null);
                    setAttachment(null);
                  }}
                  color={'error'}
                >
                  <ClearIcon />
                </IconButton>
              )}
            </Box>
          </Box>
        </form>
        <SimpleActions
          sx={{ mt: 2 }}
          confirmLabel={'Preview'}
          // form={SCHEDULED_MESSAGE_FORM_ID}
          onConfirm={() => {
            blastMessageModal.close();
            setTimeout(() => confirmModal.open(), 150);
          }}
          onCancel={handleCancelBlastMessageModal}
          disableConfirm={nameContent.length === 0 || RequestStatus.isFetching(fetchStatus)}
          loading={RequestStatus.isFetching(fetchStatus)}
        />
      </BlastMessageModal>
      <ConfirmModal
        {...confirmModal.props}
        handleClose={handleCancelConfirmModal}
        sx={{
          width: 'fit-content',
          height: 'fit-content',
          minWidth: '700px',
          maxWidth: '700px',
          overflow: 'auto',
        }}
      >
        <CampaignIcon color={'primary'} sx={{ height: '64px', width: '64px' }} />
        <Typography color="textPrimary" variant="h5" sx={{ marginBottom: '20px' }}>
          Preview blast message
        </Typography>
        {/*<Scrollbar*/}
        {/*  style={{*/}
        {/*    height: '400px',*/}
        {/*    margin: '16px 0 16px 0',*/}
        {/*  }}*/}
        {/*>*/}
        {getValues('text').length > 0 && (
          <ChatMessage
            uuid={''}
            textBody={getValues('text')}
            contentType={'text'}
            createdAt={Date.now()}
            senderName={''}
            senderType={'contact'}
            sender={{
              uuid: '',
              username: '',
            }}
            extraContext={null}
            automatedTag={''}
            action={''}
            pinConfig={{
              is_pinned: false,
              showPinActions: false,
            }}
            hideFingerMenu={true}
          />
        )}
        {image?.image?.image && (
          <ChatMessage
            uuid={''}
            imageBody={{
              uuid: '',
              url: image.image.image,
              thumbnails: {
                thumb: image.image.image,
                avatar: image.image.image,
              },
            }}
            contentType={'image'}
            createdAt={Date.now()}
            senderName={''}
            senderType={'contact'}
            sender={{
              uuid: '',
              username: '',
            }}
            extraContext={null}
            automatedTag={''}
            action={''}
            pinConfig={{
              is_pinned: false,
              showPinActions: false,
            }}
            hideFingerMenu={true}
          />
        )}
        {attachment && (
          <ChatMessage
            uuid={''}
            contentType={'text'}
            createdAt={Date.now()}
            senderName={''}
            senderType={'contact'}
            sender={{
              uuid: '',
              username: '',
            }}
            extraContext={{
              type: attachment.type,
              link: attachment.name,
            }}
            automatedTag={''}
            action={''}
            pinConfig={{
              is_pinned: false,
              showPinActions: false,
            }}
            hideFingerMenu={true}
          />
        )}
        {/*</Scrollbar>*/}
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            gap: '132px',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              gap: 1,
            }}
          >
            <Button
              type="button"
              onClick={handleCancelConfirmModal}
              color="primary"
              sx={{
                m: 1,
                margin: 0,
                minWidth: '160px',
                py: 1,
                fontSize: '16px',
              }}
              variant="outlined"
            >
              Cancel
            </Button>
            <Button
              type="button"
              onClick={() => {
                confirmModal.close();
                setTimeout(() => blastMessageModal.open(), 150);
              }}
              color="primary"
              sx={{
                m: 1,
                margin: 0,
                minWidth: '160px',
                py: 1,
                fontSize: '16px',
              }}
              variant="outlined"
            >
              Back
            </Button>
          </Box>
          <Button
            // type={'submit'}
            form={BLAST_MESSAGE_FORM_ID}
            sx={{
              m: 1,
              minWidth: '160px',
              margin: 0,
              py: 1,
              fontSize: '16px',
            }}
            variant="contained"
            onClick={() => onSubmit(getValues())}
            disabled={
              RequestStatus.isFetching(fetchStatus) ||
              RequestStatus.isFetching(files?.[BLAST_MESSAGE_STORE_KEY]?.fetchStatus)
            }
          >
            Submit
            {(RequestStatus.isFetching(fetchStatus) ||
              RequestStatus.isFetching(files?.[BLAST_MESSAGE_STORE_KEY]?.fetchStatus)) && (
              <CircularProgress size={16} sx={{ ml: 1, color: 'white' }} />
            )}
          </Button>
        </Box>
      </ConfirmModal>
      <Button
        variant={'contained'}
        color={'primary'}
        startIcon={<CampaignIcon />}
        fullWidth={buttonFullWidth}
        sx={{
          justifyContent: 'flex-start',
          ...buttonSx,
        }}
        onClick={() => blastMessageModal.open()}
      >
        Blast Message
      </Button>
      {expandMedia && image?.image?.image && (
        <Lightbox large={image?.image?.image} onClose={(): void => setExpandMedia(false)} />
      )}
      <input hidden ref={fileInputRef} type="file" onChange={handleAttachmentAdd} />
    </>
  );
};

export default BlastMessageModal;
