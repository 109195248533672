import type { FC } from 'react';
import { useCallback, useEffect, useRef, useState } from 'react';
import { Box, Card, Container, Grid, Typography } from '@mui/material';
import gtm from '../../lib/gtm';
import {
  cancelThreadsFetching,
  changeMesssagePinnedStatus,
  clearNewThreadMessages,
  clearThreadDetails,
  clearThreadMessages,
  clearThreadMessagesPost,
  clearThreadPinnedMessages,
  clearThreadsList,
  exportAllMessages,
  exportMessagesByDateRange,
  exportMessagesBySelection,
  getNewThreadMessages,
  getThreadDetails,
  getThreadMessages,
  getThreadPinnedMessages,
  getThreads,
  postEndChat,
  postInitPayment,
  postMarkAllAsRead,
  postRequestLogin,
  postThreadMessage,
  resetExportedMessages,
  setClearCurrentMessages,
  setMarkAllAsReadFetchStatus,
  setPinnedMessageUuid,
  setThreadsListFetchStatus,
} from '../../slices/messaging';
import { postClearThreadNotifications } from '../../slices/notifications';
import { RootState, useDispatch, useSelector } from '../../store';
import MessagingSidebar from './MessagingSidebar/MessagingSidebar';
import MessagingChatBox from './MessagingChatBox/MessagingChatBox';
import { Helmet } from 'react-helmet-async';
import useSettings from '../../hooks/useSettings';
import GenericBreadcrumb from '../../components/GenericBreadcrumb';
import useMounted from '../../hooks/useMounted';
import { MessagingThread } from '../../types/messaging_thread';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { getMessagingDetailsPath } from '../../routesPaths';
import { experimentalStyled } from '@mui/material/styles';
import { clearUploadDetails, postFile } from '../../slices/fileUpload';
import { RequestStatus } from '../../utils/RequestStatus';
import { useFCMBGMessageOrSocketChannel } from '../../hooks/useFCMBGMessageOrSocketChannel';
import { debounce, isArray } from 'lodash';
import useFirstRender from '../../hooks/useFirstRender';
import useShouldChatScroll from '../../hooks/useShouldChatScroll';
import { useRefState } from '../../hooks/use-ref-state';
import { LoadMoreButtonsConfig } from '../../types/messaging_load_more_buttons_config';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import toast from 'react-hot-toast';
import GrantAccessToChatContextProvider from '../../contexts/GrantAccessToChatContext';
import BlastMessageModal from './BlastMessageModal';
import MessagingSettingsModal from './MessagingSettings/MessagingSettingsModal';

const CardContainer = experimentalStyled('div')(({ theme }) => ({
  display: 'block',
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  bottom: 10,
}));

const fileStoreKey = 'messageInputFile';

const refreshTime = 1000 * 60;

const autoscrollMaxDistancePx = 100;

const defaultPageSize = 20;

const Messaging: FC = () => {
  const { settings } = useSettings();
  const navigate = useNavigate();
  const { uuid } = useParams();
  const mounted = useMounted();
  const dispatch = useDispatch();
  const firstRender = useFirstRender();
  const {
    items: threadsList,
    fetchStatus: threadsFetchStatus,
    next: nextThreadCursor,
    count: threadsCount,
    initialLength: initialThreadsLength,
    wasFetchingCancelled: wasThreadsFetchingCancelled,
    isLoadingMore,
  } = useSelector((state: RootState) => state.messaging.threadsList);
  const {
    details: threadDetails,
    messages: threadMessages,
    newMessages: threadNewMessages,
    pinnedMessages: threadPinnedMessages,
    actions: threadActions,
  } = useSelector((state) => state.messaging.thread);
  const {
    fetchStatus: detailsFetchStatus,
    participants: threadParticipants,
    active_participants: threadAciveParticipants,
  } = threadDetails;
  const {
    fetchStatus: messagesFetchStatus,
    items: messages,
    postFetchStatus: postMessageFetchStatus,
    nextCursor: nextMessagesCursor,
    previousCursor: prevMessagesCursor,
    count: messagesCount,
    initialLength: initialMessagesLength,
  } = threadMessages;
  const {
    fetchStatus: newMessagesFetchStatus,
    items: newMessages,
    count: newMessagesCount,
  } = threadNewMessages;
  const {
    fetchStatus: pinnedMessagesFetchStatus,
    items: pinnedMessages,
    nextCursor: nextPinnedMessagesCursor,
    count: pinnedMessagesCount,
    initialLength: initialPinnedMessagesLength,
  } = threadPinnedMessages;
  const { fetchStatus: fileUploadFetchStatus, uploadResponse: fileUploadResponse } = useSelector(
    (state: RootState) => state.fileUpload.files[fileStoreKey] || {}
  );
  const { bySelection, byDateRange, all, exportErrors } = useSelector(
    (state: RootState) => state.messaging.thread.caseManagement
  );
  const { user, token } = useSelector((state: RootState) => state.user);
  const [threadClickState, setThreadClickState] = useState({
    wasClicked: false,
    wasUpdateAfterClick: false,
  });
  const [wasScrolled, setWasScrolled] = useState(false);
  let [searchParams, setSearchParams] = useSearchParams({ search: '' });
  const otherSearchParams = Object.fromEntries(searchParams.entries());
  let [queryState, setQueryState] = useState<string>(searchParams.get('search'));
  const searchQuery = searchParams.get('search');
  const getThreadsTimeoutRef: any = useRef(null);
  const getMessagesTimeoutRef: any = useRef(null);
  // to have current value when refresh in interval
  const threadsCountRef: any = useRef(threadsCount);
  const messagesConfigRef: any = useRef({
    nextMessagesCursor,
    prevMessagesCursor,
    messagesCount,
  });
  const pinnedMessagesConfigRef: any = useRef({
    nextPinnedMessagesCursor,
    pinnedMessagesCount,
  });
  const shouldReloadThreadsAfterMessageRef = useRef(false);
  const wasMessagesSendRef = useRef(false);

  const [chatMessagesConfig, chatMessagesConfigRef, setChatMessagesConfig] = useRefState<{
    shouldReloadMessagesOnPollingOrNotification: boolean;
    previousMostRecentMessage: number | null;
    newMessagesCount: number;
  }>({
    shouldReloadMessagesOnPollingOrNotification: true,
    previousMostRecentMessage: null,
    newMessagesCount: 0,
  });

  const [newMessagesButtonConfig, setNewMessagesButtonConfig] = useState<{
    visible: boolean;
    title: string;
    onClick: () => void;
  }>({ visible: false, title: null, onClick: null });

  const [resetFileInput, setResetFileInput] = useState<boolean>(false);

  const [pinNewMessages, setPinNewMessages] = useState<boolean>(false);

  const isUserTechnician = user?.group_name === 'technicians';

  const performDebouncedRequest = useCallback(
    debounce((query) => {
      setSearchParams({
        ...otherSearchParams,
        search: query,
      });
      clearThreadsList(null);
      cancelThreadsFetchingAndRun(() =>
        dispatch(getThreads({ search: query, limit: initialThreadsLength, offset: 0 }))
      );
    }, 1000),
    []
  );

  const cancelThreadsFetchingAndRun = (callback) => {
    dispatch(setThreadsListFetchStatus(RequestStatus.status.FETCHING));
    cancelThreadsFetching && cancelThreadsFetching();
    setTimeout(() => {
      callback();
    }, 100);
  };

  useEffect(() => {
    if (!firstRender) {
      performDebouncedRequest(queryState);
    }
  }, [queryState]);

  const setSearchQuery = (query) => {
    setQueryState(query);
  };

  const handleSendMessage = (
    message,
    type: string = 'text',
    threadUuid,
    threads: Array<any>,
    shouldPinMessage: boolean = false
  ) => {
    wasMessagesSendRef.current = true;
    let threadOnTopUuid = threads && threads[0]?.uuid;
    if (threadUuid !== threadOnTopUuid) {
      shouldReloadThreadsAfterMessageRef.current = true;
    } else {
      shouldReloadThreadsAfterMessageRef.current = false;
    }
    if (message) dispatch(postThreadMessage({ id: uuid, [type]: message, shouldPinMessage }));
  };

  const handleSendUploadedAttachment = (response) => {
    if (response.meta.requestStatus === 'fulfilled') {
      const fileUploadResponse = response.payload.results;
      let attachmentUuid = fileUploadResponse[0].payload.uuid;
      const type = fileUploadResponse[0].type;
      if (type === 'image')
        handleSendMessage(attachmentUuid, 'image', uuid, threadsList, pinNewMessages);
      else handleSendMessage(attachmentUuid, 'file', uuid, threadsList, pinNewMessages);
      dispatch(clearUploadDetails(null));
    }
    if (response.meta.requestStatus === 'rejected') {
      toast.error("File couldn't be uploaded.");
      dispatch(clearUploadDetails(null));
    }
  };

  const handleFileChange = async (fileGroup) => {
    if (fileGroup) {
      if (fileGroup.file.length > 1)
        for (let i = 0; i < fileGroup.file.length; i++) {
          await dispatch(
            postFile({
              id: uuid,
              file: fileGroup.file[i],
              storeKey: fileStoreKey,
            })
          )
            .then((response) => {
              handleSendUploadedAttachment(response);
            })
            .then(() => {
              if (i === fileGroup.file.length - 1) {
                setResetFileInput(true);
                setPinNewMessages(false);
              }
            });
        }
      else {
        const file: File = fileGroup.file[0];
        dispatch(
          postFile({
            id: uuid,
            file,
            storeKey: fileStoreKey,
            type: file.type,
          })
        )
          .then((response) => {
            handleSendUploadedAttachment(response);
          })
          .then(() => {
            setResetFileInput(true);
            setPinNewMessages(false);
          });
      }
    }
  };

  const getThreadsWithInterval = () => {
    cancelThreadsFetchingAndRun(() =>
      dispatch(
        getThreads({
          search: searchQuery,
          limit: threadsCountRef.current || initialThreadsLength,
          offset: 0,
        })
      )
    );
    getThreadsTimeoutRef.current && clearTimeout(getThreadsTimeoutRef.current);
    getThreadsTimeoutRef.current = setTimeout(() => {
      getThreadsWithInterval();
    }, refreshTime);
  };

  const getThreadMessagesWithInterval = (params: {
    id?: string | number;
    cursor?: string;
    page_size?: string | number;
    options?: {
      dispatchedFromPinnedMessages?: boolean;
      omitGetNewThreadMessages?: boolean;
      clearCurrentMessages?: boolean;
    };
  }) => {
    const { cursor: _cursor, id: _id, page_size: _page_size, options: _options } = params;

    const options = {
      dispatchedFromPinnedMessages: false,
      omitGetNewThreadMessages: false,
      clearCurrentMessages: true,
      ..._options,
    };

    if (options.dispatchedFromPinnedMessages) {
      setChatMessagesConfig((prev) => ({
        ...prev,
        shouldReloadMessagesOnPollingOrNotification: false,
        previousMostRecentMessage:
          chatMessagesConfigRef.current.previousMostRecentMessage ??
          Date.parse(messages[messages.length - 1].created),
      }));
    }

    dispatch(setClearCurrentMessages(options.clearCurrentMessages));

    if (_id || uuid) {
      if (
        chatMessagesConfigRef.current.shouldReloadMessagesOnPollingOrNotification ||
        options.omitGetNewThreadMessages ||
        options.dispatchedFromPinnedMessages
      ) {
        if (chatMessagesConfigRef.current.newMessagesCount > 0 && prevMessagesCursor !== null)
          dispatch(
            getNewThreadMessages({
              id: _id || uuid,
              cursor: null,
              page_size: 26,
            })
          );
        else dispatch(clearNewThreadMessages(null));
        dispatch(
          getThreadMessages({
            id: _id || uuid,
            cursor:
              typeof _cursor != 'undefined'
                ? _cursor
                : messagesConfigRef.current.nextMessagesCursor,
            page_size:
              typeof _page_size != 'undefined'
                ? _page_size
                : messagesConfigRef.current.messagesCount,
          })
        );
      } else {
        dispatch(
          getNewThreadMessages({
            id: _id || uuid,
            cursor: null,
            page_size: 26,
          })
        );
      }
    }

    getMessagesTimeoutRef.current && clearTimeout(getMessagesTimeoutRef.current);
    getMessagesTimeoutRef.current = setTimeout(() => {
      getThreadMessagesWithInterval({
        id: _id || uuid,
        cursor: null,
        page_size: messagesConfigRef.current.messagesCount,
      });
    }, refreshTime);
  };

  const handleGetThreadPinnedMessages = (params: {
    id?: string | number;
    cursor?: string;
    page_size?: string | number;
  }) => {
    const { cursor: _cursor, id: _id, page_size: _page_size } = params;

    (_id || uuid) &&
      dispatch(
        getThreadPinnedMessages({
          id: _id || uuid,
          cursor:
            typeof _cursor != 'undefined'
              ? _cursor
              : pinnedMessagesConfigRef.current.nextPinnedMessagesCursor,
          page_size:
            typeof _page_size != 'undefined'
              ? _page_size
              : pinnedMessagesConfigRef.current.pinnedMessagesCount,
        })
      );
  };

  const handleThreadClick = (thread: MessagingThread): void => {
    navigate(`${getMessagingDetailsPath({ id: thread.uuid })}?${searchParams.toString()}`, {
      replace: true,
    });
    setThreadClickState({ wasClicked: true, wasUpdateAfterClick: false });
  };

  const handleLoadMoreThreadsClick = () => {
    cancelThreadsFetchingAndRun(() =>
      dispatch(
        getThreads({
          search: searchQuery,
          limit: initialThreadsLength,
          offset: threadsCount,
          isLoadingMore: true,
        })
      )
    );
  };

  const handleLoadMoreMessagesClick = () => {
    getThreadMessagesWithInterval({
      id: uuid,
      cursor: nextMessagesCursor,
      page_size: initialMessagesLength,
    });
  };

  const handleLoadMorePinnedMessagesClick = () => {
    handleGetThreadPinnedMessages({
      id: uuid,
      cursor: nextPinnedMessagesCursor,
      page_size: initialPinnedMessagesLength,
    });
  };

  const handlePostEndChat = () => {
    dispatch(postEndChat({ uuid }));
  };

  const handlePostInitPayment = (amount) => {
    dispatch(postInitPayment({ uuid, amount }));
  };

  const handlePostRequestLogin = () => {
    dispatch(postRequestLogin({ uuid }));
  };

  const handlePostMarkAllAsRead = () => {
    dispatch(postMarkAllAsRead({}));
  };

  const handleResetChatMessagesConfig = (): void => {
    setChatMessagesConfig({
      shouldReloadMessagesOnPollingOrNotification: true,
      previousMostRecentMessage: null,
      newMessagesCount: 0,
    });
  };

  const handleResetNewMessagesButtonConfig = (): void => {
    setNewMessagesButtonConfig({ visible: false, title: null, onClick: null });
  };

  const checkIfMessageLoaded = (params: { uuid: string }): boolean => {
    return Boolean(messages.find((message) => message.uuid === params.uuid));
  };

  let isLoadMoreThreadVisible = !!nextThreadCursor;
  let isLoadMoreMessagesVisible = !!nextMessagesCursor;
  let isLoadMorePinnedMessagesVisible = !!nextPinnedMessagesCursor;

  const isAnyThreadLoading =
    RequestStatus.isFetching(threadsFetchStatus) ||
    RequestStatus.isFetching(threadActions?.markAllAsReadFetchStatus);

  const isAnyLoading =
    RequestStatus.isFetching(messagesFetchStatus) ||
    RequestStatus.isFetching(newMessagesFetchStatus) ||
    RequestStatus.isFetching(fileUploadFetchStatus) ||
    RequestStatus.isFetching(postMessageFetchStatus) ||
    RequestStatus.isFetching(threadActions?.requestLoginFetchStatus) ||
    RequestStatus.isFetching(threadActions?.endChatFetchStatus) ||
    RequestStatus.isFetching(threadActions?.initPaymentFetchStatus);

  const notificationEvent = useFCMBGMessageOrSocketChannel(
    {
      uuid: user?.uuid,
      token,
    },
    [user?.uuid, token]
  );

  const loadMoreButtonsConfig: LoadMoreButtonsConfig = {
    loadOlder: {
      title: 'Load older messages',
      onClick: () => {
        getThreadMessagesWithInterval({
          cursor: nextMessagesCursor,
          page_size: initialMessagesLength,
          options: { omitGetNewThreadMessages: true, clearCurrentMessages: false },
        });
      },
      visible: !!nextMessagesCursor,
      disabled: isAnyLoading,
      loading: isAnyLoading,
      icon: <ArrowUpwardIcon />,
    },
    loadNewer: {
      title: 'Load newer messages',
      onClick: () => {
        getThreadMessagesWithInterval({
          cursor: prevMessagesCursor,
          page_size: initialMessagesLength,
          options: { omitGetNewThreadMessages: true, clearCurrentMessages: false },
        });
      },
      visible: !!prevMessagesCursor,
      disabled: isAnyLoading,
      loading: isAnyLoading,
      icon: <ArrowDownwardIcon />,
    },
  };

  const handleScrollUp = (event) => {
    const maxScrollHeight =
      document.querySelectorAll('.scrollbar-container')[1]?.scrollHeight -
      document.querySelectorAll('.scrollbar-container')[1]?.clientHeight;
    const distanceFromBottom = parseInt(event.children[2].style.top || 0);
    // unlock autoscroll on a few px from bottom
    setWasScrolled(maxScrollHeight - distanceFromBottom > autoscrollMaxDistancePx);
  };

  const shouldChatScroll = useShouldChatScroll(
    {
      id: uuid,
      messages,
      limit: initialMessagesLength,
      wasScrolledUnlocked: wasScrolled,
      wasMessagesSendRef,
    },
    [uuid, messages]
  );

  useEffect(() => {
    threadsCountRef.current = threadsCount;
  }, [threadsCount]);

  useEffect(() => {
    messagesConfigRef.current = {
      ...messagesConfigRef.current,
      messagesCount,
      nextMessagesCursor,
      prevMessagesCursor,
    };
    if (prevMessagesCursor !== null && chatMessagesConfigRef.current.newMessagesCount === 0)
      setNewMessagesButtonConfig({
        visible: true,
        title: `Go to recent messages`,
        onClick: () => {
          handleResetChatMessagesConfig();
          //to make sure shouldReloadMessagesOnPollingOrNotification is set to true
          setTimeout(() => {
            getThreadMessagesWithInterval({
              cursor: null,
              page_size: 20,
            });
          }, 150);
        },
      });
    else if (newMessagesButtonConfig.visible && prevMessagesCursor === null) {
      setNewMessagesButtonConfig((prev) => ({
        ...prev,
        visible: false,
        onClick: null,
      }));
      //to prevent button title changing before opacity transition finishes
      setTimeout(() => {
        setNewMessagesButtonConfig((prev) => ({
          ...prev,
          title: null,
        }));
      }, 150);
      handleResetChatMessagesConfig();
    }
  }, [messagesCount, nextMessagesCursor, prevMessagesCursor]);

  useEffect(() => {
    pinnedMessagesConfigRef.current = {
      ...pinnedMessagesConfigRef.current,
      pinnedMessagesCount,
      nextPinnedMessagesCursor,
    };
  }, [pinnedMessagesCount, nextPinnedMessagesCursor]);

  const reloadThreadsList = () => {
    cancelThreadsFetchingAndRun(() =>
      dispatch(
        getThreads({
          search: queryState,
          limit:
            threadsCountRef.current < defaultPageSize || initialThreadsLength < defaultPageSize
              ? defaultPageSize
              : threadsCountRef.current || initialThreadsLength,
          offset: 0,
        })
      )
    );
  };

  const handleChangeMesssagePinnedStatus = (data: { is_pinned: boolean; uuid: string }) => {
    dispatch(setPinnedMessageUuid(data.uuid));
    dispatch(changeMesssagePinnedStatus({ thread_uuid: uuid, ...data }));
  };

  const handleExportMessages = (
    data: {
      name: string;
      start_date: string;
      end_date: string;
      start_message_uuid: string;
      end_message_uuid: string;
    },
    exportType: 'all' | 'dateRange' | 'selection'
  ): void => {
    const { name, start_date, end_date, start_message_uuid, end_message_uuid } = data;
    if (exportType === 'dateRange')
      dispatch(exportMessagesByDateRange({ name, start_date, end_date, thread_uuid: uuid }));
    else if (exportType === 'selection')
      dispatch(
        exportMessagesBySelection({ name, start_message_uuid, end_message_uuid, thread_uuid: uuid })
      );
    else dispatch(exportAllMessages({ name, thread_uuid: uuid }));
  };

  useEffect(() => {
    if (!notificationEvent) return;
    reloadThreadsList();
    if (notificationEvent?.data_message?.thread_uuid === uuid) {
      getThreadMessagesWithInterval({
        id: uuid,
        page_size: !prevMessagesCursor ? messagesCount + 1 : newMessagesCount + 1,
        cursor: null,
        options: {
          clearCurrentMessages: Boolean(!prevMessagesCursor),
        },
      });
    }
  }, [notificationEvent]);

  useEffect(() => {
    if (
      threadActions?.markAllAsReadFetchStatus &&
      RequestStatus.isDone(threadActions?.markAllAsReadFetchStatus)
    ) {
      dispatch(setMarkAllAsReadFetchStatus(RequestStatus.status.NULL));
      reloadThreadsList();
    }
  }, [threadActions?.markAllAsReadFetchStatus]);

  // useEffect(() => {
  //   if (RequestStatus.isDone(fileUploadFetchStatus) && fileUploadResponse) {
  //     let attachmentUuid = fileUploadResponse[0].payload.uuid;
  //     const type = fileUploadResponse[0].type;
  //     if (type === 'image') handleSendMessage(attachmentUuid, 'image', uuid, threadsList);
  //     else handleSendMessage(attachmentUuid, 'file', uuid, threadsList);
  //     dispatch(clearUploadDetails(null));
  //   }
  //   if (RequestStatus.isError(fileUploadFetchStatus)) {
  //     toast.error("File couldn't be uploaded.");
  //     dispatch(clearUploadDetails(null));
  //   }
  // }, [fileUploadFetchStatus, fileUploadResponse]);

  useEffect(() => {
    if (RequestStatus.isDone(postMessageFetchStatus) && uuid && !firstRender) {
      handleResetChatMessagesConfig();
      handleResetNewMessagesButtonConfig();
      dispatch(clearThreadMessagesPost(null));
      dispatch(getThreadDetails({ id: uuid }));
      setTimeout(() => {
        getThreadMessagesWithInterval({
          id: uuid,
          page_size: messagesCount + 1,
          cursor: null,
          options: { omitGetNewThreadMessages: true },
        });
      }, 150);
    }
  }, [postMessageFetchStatus]);

  useEffect(() => {
    gtm.push({ event: 'page_view' });
  }, []);

  useEffect(() => {
    !getThreadsTimeoutRef?.current && getThreadsWithInterval();
    return () => {
      getThreadsTimeoutRef.current && clearTimeout(getThreadsTimeoutRef.current);
      getMessagesTimeoutRef.current && clearTimeout(getMessagesTimeoutRef.current);
      dispatch(clearThreadDetails(null));
      dispatch(clearThreadMessages(null));
      dispatch(getNewThreadMessages(null));
      dispatch(clearThreadPinnedMessages(null));
      dispatch(clearThreadMessagesPost(null));
    };
  }, [mounted, uuid]);

  useEffect(() => {
    if (!uuid) return;
    dispatch(getThreadDetails({ id: uuid }));
    getMessagesTimeoutRef.current && clearTimeout(getMessagesTimeoutRef.current);
    handleResetChatMessagesConfig();
    handleResetNewMessagesButtonConfig();
    // clear old messages data
    dispatch(clearThreadMessages(null));
    dispatch(clearNewThreadMessages(null));
    dispatch(clearThreadPinnedMessages(null));
    messagesConfigRef.current = { messagesCount: initialMessagesLength, nextMessagesCursor: null };
    pinnedMessagesConfigRef.current = {
      pinnedMessagesCount: initialPinnedMessagesLength,
      nextPinnedMessagesCursor: null,
    };
    getThreadMessagesWithInterval({ cursor: null, options: { omitGetNewThreadMessages: true } });
    handleGetThreadPinnedMessages({ cursor: null });
    return () => {
      dispatch(clearThreadDetails(null));
      dispatch(clearThreadMessages(null));
      dispatch(clearNewThreadMessages(null));
      dispatch(clearThreadPinnedMessages(null));
    };
  }, [uuid]);

  useEffect(
    function refreshThreadsBadgesAfterClick() {
      if (
        threadClickState.wasClicked &&
        !threadClickState.wasUpdateAfterClick &&
        RequestStatus.isDone(messagesFetchStatus) &&
        RequestStatus.isDone(detailsFetchStatus) &&
        !firstRender
      ) {
        setTimeout(() => {
          cancelThreadsFetchingAndRun(() =>
            dispatch(
              getThreads({
                search: searchQuery,
                limit: threadsCountRef.current || initialThreadsLength,
                offset: 0,
              })
            )
          );
        }, 100);
        setThreadClickState({ wasClicked: false, wasUpdateAfterClick: false });
      }
    },
    [threadClickState, messagesFetchStatus, detailsFetchStatus]
  );

  useEffect(
    function refreshGlobalBadges() {
      if (RequestStatus.isDone(messagesFetchStatus)) {
        uuid && dispatch(postClearThreadNotifications({ uuid }));
        if (shouldReloadThreadsAfterMessageRef.current) {
          shouldReloadThreadsAfterMessageRef.current = false;
          cancelThreadsFetchingAndRun(() =>
            dispatch(
              getThreads({
                search: searchQuery,
                limit: threadsCountRef.current || initialThreadsLength,
                offset: 0,
              })
            )
          );
        }
      }
    },
    [messagesFetchStatus]
  );

  useEffect(() => {
    const newMessagesCount = newMessages.filter(
      (message) =>
        Date.parse(message.created) > chatMessagesConfigRef.current.previousMostRecentMessage
    ).length;

    setChatMessagesConfig((prev) => ({
      ...prev,
      newMessagesCount,
    }));
  }, [newMessages]);

  useEffect(() => {
    if (chatMessagesConfigRef.current.newMessagesCount > 0) {
      setNewMessagesButtonConfig({
        visible: true,
        title: `${
          chatMessagesConfigRef.current.newMessagesCount > 25
            ? '25+'
            : chatMessagesConfigRef.current.newMessagesCount
        } new message${chatMessagesConfigRef.current.newMessagesCount > 1 ? 's' : ''}!`,
        onClick: () => {
          handleResetChatMessagesConfig();
          //to make sure shouldReloadMessagesOnPollingOrNotification is set to true
          setTimeout(() => {
            // dispatch(clearNewThreadMessages(null));
            getThreadMessagesWithInterval({
              cursor: null,
              page_size: 20,
            });
          }, 150);
        },
      });
    }
  }, [chatMessagesConfig]);

  useEffect(() => {
    if (RequestStatus.isDone(threadActions?.changeMessagePinnedStatus?.postFetchStatus)) {
      handleGetThreadPinnedMessages({ id: uuid, page_size: pinnedMessagesCount, cursor: null });
    }
  }, [threadActions?.changeMessagePinnedStatus?.postFetchStatus]);

  useEffect(() => {
    const postFetchStatuses = [
      bySelection.postFetchStatus,
      byDateRange.postFetchStatus,
      all.postFetchStatus,
    ];
    if (postFetchStatuses.some((status) => RequestStatus.isDone(status))) {
      toast.success(
        'Messages are being exported. Check the Case Management tab to see current export status.',
        { duration: 4000 }
      );
      dispatch(resetExportedMessages(null));
    } else if (postFetchStatuses.some((status) => RequestStatus.isError(status))) {
      // toast.error('There was an error while exporting messages. Please try again later.');
      const errorMessage =
        Object.keys(exportErrors)?.length > 0
          ? isArray(Object.values(exportErrors)?.[0])
            ? Object.values(exportErrors)?.[0].join('. ')
            : Object.values(exportErrors)?.[0]
          : 'There was an error while exporting messages. Please try again later.';
      toast.error(errorMessage);
      dispatch(resetExportedMessages(null));
    }
  }, [bySelection.postFetchStatus, byDateRange.postFetchStatus, all.postFetchStatus]);

  return (
    <>
      <Helmet>
        <title>Messaging</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          display: 'flex',
          py: 4,
        }}
      >
        <Container maxWidth={settings.compact ? 'xl' : false}>
          <Grid container justifyContent="space-between" spacing={3}>
            <Grid item sx={{ width: '100%' }}>
              <Typography
                color="textPrimary"
                variant="h5"
                sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
              >
                <Box sx={{ display: 'flex', gap: 2 }}>
                  Messaging
                  <BlastMessageModal />
                </Box>
                <MessagingSettingsModal />
              </Typography>
              <GenericBreadcrumb replaceLastUUIDWith={'CHAT'} />
              <Box
                sx={{
                  mb: -1,
                  mx: -1,
                  mt: 1,
                }}
              ></Box>
            </Grid>
          </Grid>
          <Box
            sx={{
              mt: 3,
              mb: -5,
              pb: 3,
              flex: 1,
              position: 'relative',
              height: `calc(100% - 84px)`,
              minHeight: '200px',
            }}
          >
            <CardContainer>
              <Card sx={{ maxHeight: '100%', display: 'flex', width: '100%', height: '100%' }}>
                <GrantAccessToChatContextProvider>
                  <Box sx={{ mt: 0, flex: 1, minHeight: '100%', display: 'flex' }}>
                    <MessagingSidebar
                      threadsList={threadsList}
                      activeThreadId={uuid}
                      searchQuery={queryState}
                      setSearchQuery={setSearchQuery}
                      fetchStatus={threadsFetchStatus}
                      handleSelect={handleThreadClick}
                      onLoadMoreThreadsClick={handleLoadMoreThreadsClick}
                      isLoadMoreThreadVisible={isLoadMoreThreadVisible}
                      wasThreadsFetchingCancelled={wasThreadsFetchingCancelled}
                      isLoadingMore={isLoadingMore}
                      onPostMarkAllAsRead={handlePostMarkAllAsRead}
                      isAnyThreadLoading={isAnyThreadLoading}
                    />
                    <MessagingChatBox
                      participants={threadParticipants}
                      threadAciveParticipants={threadAciveParticipants}
                      detailsFetchStatus={detailsFetchStatus}
                      messages={messages}
                      pinnedMessages={pinnedMessages}
                      pinnedMessagesFetchStatus={pinnedMessagesFetchStatus}
                      handleSendMessage={(_imageId, _type, _uuid) =>
                        handleSendMessage(_imageId, _type, _uuid, threadsList)
                      }
                      handleGetThreadPinnedMessages={handleGetThreadPinnedMessages}
                      handleFileChange={handleFileChange}
                      isLoading={isAnyLoading}
                      thread={threadDetails}
                      onLoadMoreMessagesClick={handleLoadMoreMessagesClick}
                      isLoadMoreMessagesVisible={isLoadMoreMessagesVisible}
                      onLoadMorePinnedMessagesClick={handleLoadMorePinnedMessagesClick}
                      isLoadMorePinnedMessagesVisible={isLoadMorePinnedMessagesVisible}
                      shouldChatScroll={shouldChatScroll}
                      onScrollUp={handleScrollUp}
                      onPostEndChat={handlePostEndChat}
                      onPostInitPayment={handlePostInitPayment}
                      onPostRequestLogin={handlePostRequestLogin}
                      isUserTechnician={isUserTechnician}
                      handleChangeMesssagePinnedStatus={handleChangeMesssagePinnedStatus}
                      changeMessagePinnedPostFetchStatus={
                        threadActions?.changeMessagePinnedStatus?.postFetchStatus
                      }
                      getThreadMessagesWithInterval={getThreadMessagesWithInterval}
                      messagesFetchStatus={messagesFetchStatus}
                      checkIfMessageLoaded={checkIfMessageLoaded}
                      newMessagesButtonConfig={newMessagesButtonConfig}
                      loadMoreButtonsConfig={loadMoreButtonsConfig}
                      handleExportMessages={handleExportMessages}
                      resetFileInput={resetFileInput}
                      setResetFileInput={setResetFileInput}
                      pinNewMessages={pinNewMessages}
                      setPinNewMessages={setPinNewMessages}
                      threadType={threadDetails?.type}
                    />
                  </Box>
                </GrantAccessToChatContextProvider>
              </Card>
            </CardContainer>
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default Messaging;
