import React, { createContext, FC, useEffect, useRef, useState } from 'react';
import {
  Box,
  Button,
  CircularProgress,
  Divider,
  Grid,
  IconButton,
  LinearProgress,
  Theme,
  Typography,
} from '@mui/material';
import useModal from '../../../hooks/useModal';
import { Organization } from '../../../types/organization';
import { RootState, useDispatch, useSelector } from '../../../store';
import { compact, isEqual } from 'lodash';
import { RequestStatus } from '../../../utils/RequestStatus';
import { Add, UploadFile } from '@mui/icons-material';
import { SelectEdit, SelectView } from './SpreadsheetComponents/LocationsSpreadsheetSelect';
import { CellBase, Matrix, Mode, Point, Spreadsheet } from 'react-spreadsheet';
import ChevronUp from '../../../icons/ChevronUp';
import ChevronDown from '../../../icons/ChevronDown';
import {
  createNewStructure,
  getMediaLibraryFiles,
  resetCreateNewStructureErrors,
  resetMediaLibraryList,
} from '../../../slices/locations';
import { RemoveScroll } from 'react-remove-scroll';
import DeleteIcon from '@mui/icons-material/Delete';
import { useTheme } from '@mui/material/styles';
import MediaFilesPaginationContextProvider from '../../../contexts/MediaFilesPaginationContext';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useSearchParams } from 'react-router-dom';
import LocationsSpreadsheetCustomCell from './SpreadsheetComponents/LocationsSpreadsheetCustomCell';
import ClearIcon from '@mui/icons-material/Clear';
import { toastMessageKeyName } from '../../../utils/locations/extract-new-location-errors';
import toast from 'react-hot-toast';

interface LocationsMediaLibraryModalProps {
  organization: Organization;
  onUploadFilesClick: any;
  disabled?: boolean;
  onClick?: any;
}

export const CELL_HEIGHT = 41;

const LocationsMediaLibraryModal: FC<LocationsMediaLibraryModalProps> = (props) => {
  const { organization, onUploadFilesClick, disabled = false, onClick = null } = props;

  const dispatch = useDispatch();
  const theme = useTheme();

  const [searchParams, setSearchParams] = useSearchParams();

  const { fetchStatus, allItems } = useSelector(
    (state: RootState) => state.locations.mediaLibraryFiles
  );
  const { fetchStatus: createNewStructureFetchStatus, errors: createNewStructureErrors } =
    useSelector((state: RootState) => state.locations.createNewStructure);

  const { Component: MediaLibraryModal, ...mediaLibraryModal } = useModal();

  const columnsLabels = ['Building name', 'Floor', 'Quadrant', 'Room', 'Equipment', 'File'];
  const columnLabelForRequest = ['building', 'floor', 'area', 'room', 'equipment', 'file'];

  const emptyRow = [
    { value: undefined },
    { value: undefined },
    { value: undefined },
    { value: undefined },
    { value: undefined },
    {
      value: undefined,
      DataViewer: SelectView,
      DataEditor: SelectEdit,
      className: 'select-cell',
    },
  ];

  const [spreadsheetData, setSpreadsheetData] = useState<Matrix<CellBase<string | undefined>>>([
    emptyRow,
  ]);

  const [spreadsheetState, setSpreadsheetState] = useState<{
    active: Point | null;
    mode: Mode;
  }>({
    active: null,
    mode: null,
  });

  const spreadsheetContainerRef = useRef<HTMLDivElement>(null);

  const isLessThanLg = useMediaQuery((theme: Theme) => theme.breakpoints.down('lg'));

  const handleOnMediaLibraryModalTrigger = (): void => {
    onClick?.();
    mediaLibraryModal.open();
  };

  const resetModal = (): void => {
    // setSpreadsheetData([emptyRow]);
    dispatch(resetMediaLibraryList(null));
    dispatch(resetCreateNewStructureErrors(null));
  };

  const onChange = (val: Matrix<CellBase<string | undefined>>) => {
    if (isEqual(spreadsheetData, val)) return;
    const result: Matrix<CellBase<string | undefined>> = [];
    val.forEach((row, index) => {
      const rowResult: Array<CellBase<string | undefined>> = [
        { value: Boolean(val[index][0]?.value) ? val[index][0]?.value : undefined },
        { value: Boolean(val[index][1]?.value) ? val[index][1]?.value : undefined },
        { value: Boolean(val[index][2]?.value) ? val[index][2]?.value : undefined },
        { value: Boolean(val[index][3]?.value) ? val[index][3]?.value : undefined },
        { value: Boolean(val[index][4]?.value) ? val[index][4]?.value : undefined },
        {
          value: allItems.find((item) => item.uuid === val[index][5]?.value)
            ? val[index][5]?.value
            : undefined,
          DataViewer: SelectView,
          DataEditor: SelectEdit,
          className: 'select-cell',
        },
      ];
      result.push(rowResult);
    });
    setSpreadsheetData([...result]);
  };

  const scrollToBottom = () => {
    if (spreadsheetContainerRef?.current) {
      spreadsheetContainerRef.current.scrollTo({
        top: spreadsheetContainerRef.current.scrollHeight,
        behavior: 'smooth',
      });
    }
  };

  const scrollToTop = () => {
    if (spreadsheetContainerRef?.current) {
      spreadsheetContainerRef.current.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    }
  };

  const addNewRow = (): void => {
    setSpreadsheetData((prev) => [...prev, emptyRow]);
    setTimeout(() => {
      scrollToBottom();
    }, 100);
  };

  const removeEmptyRows = (): void => {
    dispatch(resetCreateNewStructureErrors(null));
    setSpreadsheetData((prev) => {
      let filtered = prev.filter((row) => !isEqual(row, emptyRow));

      return filtered.length > 0 ? filtered : [emptyRow];
    });
  };

  const getCellValue = (cell) => (typeof cell.value === 'undefined' ? '' : cell.value);

  const handleCreateBuilding = (): void => {
    const uuid = searchParams.get('organization_uuid');
    if (!uuid) return;
    const data = spreadsheetData
      .filter((row) => !isEqual(row, emptyRow))
      .map((row) =>
        Object.fromEntries(
          row.map((cell, idx) => [
            columnLabelForRequest[idx],
            //case for files field
            idx === 5
              ? getCellValue(cell) === ''
                ? []
                : compact([getCellValue(cell)])
              : getCellValue(cell),
          ])
        )
      );
    removeEmptyRows();

    dispatch(createNewStructure({ uuid, structure: { buildings: data } }));
  };

  useEffect(() => {
    if (!mediaLibraryModal.isOpen) {
      setTimeout(() => {
        resetModal();
      }, 150);
    }
  }, [mediaLibraryModal.isOpen]);

  useEffect(() => {
    if (RequestStatus.isDone(createNewStructureFetchStatus)) mediaLibraryModal.close();
  }, [createNewStructureFetchStatus]);

  useEffect(() => {
    if (createNewStructureErrors.length > 0 && createNewStructureErrors?.[0][toastMessageKeyName]) {
      toast.error(createNewStructureErrors?.[0][toastMessageKeyName]);
      dispatch(resetCreateNewStructureErrors(null));
    }
  });

  return (
    <>
      <MediaLibraryModal
        {...mediaLibraryModal.props}
        sx={{
          maxWidth: '80vw',
          width: '80vw',
          height: 'fit-content',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          overflow: 'visible',
        }}
      >
        <Box sx={{ display: 'flex', width: '100%' }}>
          <Box sx={{ borderRight: `solid 1px ${theme.palette.divider}`, pr: 2, mr: 2 }}>
            <UploadFile color={'primary'} sx={{ height: '64px', width: '64px' }} />
            <Typography variant={'h5'}>Create buildings</Typography>
          </Box>
          <ul>
            <li>
              <Typography variant={'subtitle2'}>
                To select a file, double-click on the cell in "File" column
              </Typography>
            </li>
            <li>
              <Typography variant={'subtitle2'}>
                You can remove all data from a row by clicking row header (a number) and then
                pressing "Backspace" (removing columns works analogically)
              </Typography>
            </li>
            <li>
              {/*<Typography variant={'subtitle2'}>*/}
              {/*  You can remove all data from a column by clicking column header header (i.e. Floor)*/}
              {/*  and then pressing "Backspace"*/}
              {/*</Typography>*/}
              <Typography variant={'subtitle2'}>
                If any of the cells has red outline, hover over the icon inside the cell to check
                what's wrong
              </Typography>
            </li>
            <li>
              <Typography variant={'subtitle2'}>
                "Remove empty rows" removes only rows where all cells are empty
              </Typography>
            </li>
            <li>
              <Typography variant={'subtitle2'}>
                Empty rows will be cleared after clicking "Create", so there's no need to remove
                them manually
              </Typography>
            </li>
          </ul>
        </Box>
        <Grid container marginTop={1} spacing={2}>
          <Grid item xs={12} md={3}>
            <Button
              sx={{ height: '100%' }}
              fullWidth
              variant={'contained'}
              startIcon={<Add />}
              onClick={addNewRow}
              disabled={RequestStatus.isFetching(createNewStructureFetchStatus)}
            >
              Add new row
            </Button>
          </Grid>
          <Grid item xs={12} md={3}>
            <Button
              sx={{ height: '100%' }}
              fullWidth
              variant={'contained'}
              startIcon={<DeleteIcon color={'error'} />}
              onClick={removeEmptyRows}
              disabled={RequestStatus.isFetching(createNewStructureFetchStatus)}
            >
              Remove empty rows
            </Button>
          </Grid>
          <Grid item xs={12} md={3}>
            <Button
              sx={{ height: '100%' }}
              fullWidth
              variant={'contained'}
              startIcon={<ClearIcon color={'error'} />}
              onClick={() => {
                setSpreadsheetData([emptyRow]);
                dispatch(resetCreateNewStructureErrors(null));
              }}
              disabled={RequestStatus.isFetching(createNewStructureFetchStatus)}
            >
              Reset spreadsheet
            </Button>
          </Grid>
          {/*{!isLessThanLg && (*/}
          {/*  <>*/}
          {/*    <Grid item xs={12} md={3} ></Grid>*/}
          {/*    <Grid item xs={12} md={3} ></Grid>*/}
          {/*  </>*/}
          {/*)}*/}
          <Grid item xs={12} md={1.5}>
            <Button
              sx={{ height: '100%' }}
              fullWidth
              variant={'outlined'}
              endIcon={<ChevronUp />}
              onClick={scrollToTop}
              disabled={RequestStatus.isFetching(createNewStructureFetchStatus)}
            >
              Scroll
            </Button>
          </Grid>
          <Grid item xs={12} md={1.5}>
            <Button
              sx={{ height: '100%' }}
              fullWidth
              variant={'outlined'}
              endIcon={<ChevronDown />}
              onClick={scrollToBottom}
              disabled={RequestStatus.isFetching(createNewStructureFetchStatus)}
            >
              Scroll
            </Button>
          </Grid>
        </Grid>
        <Box sx={{ height: '4px', width: '100%', mt: 1 }}>
          {RequestStatus.isFetching(fetchStatus) && <LinearProgress />}
        </Box>
        <MediaFilesPaginationContextProvider>
          <Box
            ref={spreadsheetContainerRef}
            sx={{
              height: 'calc(65vh - 200px)',
              padding: '0 16px 0 0',
              overflowY: 'scroll',
              width: '100%',
              '.Spreadsheet__table': { width: '100%' },
              '.Spreadsheet__table colgroup :first-of-type': { width: '50px' },
              '.Spreadsheet__table tbody :first-of-type th': {
                backgroundColor: 'primary.light',
                color: 'primary.contrastText',
                height: `${CELL_HEIGHT}px`,
                cursor: 'pointer',
              },
              ...(RequestStatus.isFetching(createNewStructureFetchStatus) && {
                filter: 'brightness(0.75)',
              }),
              transition: 'filter 150ms ease-in-out',
            }}
          >
            <RemoveScroll
              enabled={spreadsheetState.active?.column === 5 && spreadsheetState.mode === 'edit'}
            >
              <Spreadsheet
                data={spreadsheetData}
                onChange={onChange}
                columnLabels={columnsLabels}
                onActivate={(active) => {
                  setSpreadsheetState((prev) => ({ ...prev, active }));
                }}
                onModeChange={(mode) => setSpreadsheetState((prev) => ({ ...prev, mode }))}
                Cell={LocationsSpreadsheetCustomCell}
              />
            </RemoveScroll>
          </Box>
        </MediaFilesPaginationContextProvider>
        <Grid container spacing={2} justifyContent={'space-between'} marginTop={2}>
          <Grid item xs={12} md={3}>
            <Button
              sx={{ py: 1, fontSize: '16px' }}
              fullWidth
              variant={'outlined'}
              onClick={() => {
                mediaLibraryModal.close();
              }}
            >
              Close
            </Button>
          </Grid>
          <Grid item xs={12} md={3}></Grid>
          <Grid item xs={12} md={3}></Grid>
          <Grid item xs={12} md={3}>
            <Button
              sx={{ py: 1, fontSize: '16px', gap: '8px' }}
              fullWidth
              variant={'contained'}
              onClick={handleCreateBuilding}
            >
              Create
              {RequestStatus.isFetching(createNewStructureFetchStatus) && (
                <CircularProgress size={16} sx={{ color: theme.palette.primary.contrastText }} />
              )}
            </Button>
          </Grid>
        </Grid>
      </MediaLibraryModal>
      <Button
        fullWidth
        variant={'contained'}
        startIcon={<UploadFile />}
        disabled={disabled}
        onClick={handleOnMediaLibraryModalTrigger}
      >
        Create buildings
      </Button>
    </>
  );
};

export default LocationsMediaLibraryModal;
